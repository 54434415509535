import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { FaMapMarkerAlt } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TfiBackLeft } from "react-icons/tfi";
import api from "../api";
import "./Home/Home.css";
import Table from "react-bootstrap/Table";
import { FaRegEye } from "react-icons/fa";
import { backdropClasses, colors } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { LuMapPin } from "react-icons/lu";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import logi from "../Components/Login/biker_image.png";
import { convertLength } from "@mui/material/styles/cssUtils";

const ViewModal = () => {
  const [activeTab, setActiveTab] = useState("current");
  const [meetingType, setMeetingType] = useState(1);
  const [imagePreview, setImagePreview] = useState();
  const [meetingList, setMeetingList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [viewmodaldata, setViewmodaldata] = useState();
  const [isloading, setIsloading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { data } = location.state;
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [show, setShow] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [loadingMeetingImage, setLoadingMeetingImage] = useState(null);
  const [modaldata, setModaldata] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseImage = () => setShowImage(false);
  const handleShowImage = () => setShowImage(true);
  console.log("meetingList", meetingList);
  const getMeeting = () => {
    api
      .listMeeting(userData, {
        created_by: data.user_id,
        meeting_type: meetingType,
        // paginate: 0,
        // pageno: currentPage - 1,
      })
      .then((res) => {
        setMeetingList(res.data.data.list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMeetingImage = (meeting) => {
    setModaldata(meeting);
    setIsloading(true);
    setLoadingMeetingImage(meeting.meeting_id);
    api
      .listMeetingDetail(userData, { meeting_id: meeting.meeting_id })
      .then((res) => {
        setViewmodaldata(res.data.data.list);
        handleShow();
        setIsloading(false);
        setLoadingMeetingImage(null);
      })
      .catch((err) => {
        console.log(err);
        setLoadingMeetingImage(null);
      });
  };

  useEffect(() => {
    getMeeting();
  }, [meetingType]);

  const openMapInNewTab = () => {
    navigate("/MapDetails", { state: { data: data } });
  };
  const handleGoMap = (meeting) => {
    console.log("meeting",meeting);
    let test = meeting
    navigate(
      `/location`,
      {
        state: {
          data: meeting
        },
      })
    // navigate("/location", { state: { data: meeting } });

  };
console.log("data: " , data);
  return (
    <div>
      <div className="viewmodal">
        <button onClick={() => navigate("/home")}>
          <TfiBackLeft /> Back to Home
        </button>

        <button style={{ float: "right" }} onClick={openMapInNewTab}>
          View Map <LuMapPin />
        </button>

        <hr />
        <div className="personDetails mb-2">
          <h2>
            Name :- {data.fname} {data.lname}
          </h2>
          <p className="m-0">Username :- {data.username}</p>
          <p className="m-0">
            Role :-
            {userData?.data?.comp_data?.comp_type == 2
              ? "Salesman"
              : "Delivery Boy"}
          </p>
          <p className="m-0">
            Status :-{" "}
            {data.status === 3
              ? "Offline"
              : data.status === 2
                ? "Online"
                : "Offline"}
          </p>
        </div>

        <Tab.Container activeKey={activeTab}>
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link
                eventKey="past"
                onClick={() => {
                  setActiveTab("past");
                  setMeetingType(2);
                  setMeetingList([]);
                }}
              >
                Previous
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="current"
                onClick={() => {
                  setActiveTab("current");
                  setMeetingType(1);
                  setMeetingList([]);
                }}
              >
                Current
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="upcoming"
                onClick={() => {
                  setActiveTab("upcoming");
                  setMeetingType(3);
                  setMeetingList([]);
                }}
              >
                Upcoming
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="past">
              <div className="table-responsive">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Company Name</th>
                      <th>Company Tin</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th>Contact Person</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                      <th>Remarks</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {meetingList?.length > 0 ? (
                      meetingList?.map((meeting, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{meeting.comp_name}</td>
                          <td>{meeting.comp_tin}</td>
                          <td>{meeting.cont_email}</td>
                          <td>{JSON.parse(meeting?.cont_mobile)}</td>
                          <td>{meeting.cont_person}</td>
                          <td>
                            {moment(meeting.meeting_st_date).format(
                              "DD/MM/YYYY hh:mm A"
                            )}
                          </td>
                          <td>
                            {meeting.meeting_end_date
                              ? moment(meeting.meeting_end_date).format(
                                "DD/MM/YYYY hh:mm A"
                              )
                              : " "}
                          </td>
                          <td>{meeting.meet_remarks}</td>
                          <td>
                            <span
                              style={{
                                width: "35px",
                                height: "30px",
                                padding: "3px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                getMeetingImage(meeting);
                              }}
                            >
                              {loadingMeetingImage === meeting.meeting_id ? (
                                <Spinner
                                  animation="border"
                                  variant="dark"
                                  size="sm"
                                />
                              ) : (
                                <FaRegEye
                                  style={{ color: "white", fontSize: "20px" }}
                                />
                              )}
                            </span>
                            <span
                              style={{
                                width: "35px",
                                height: "30px",
                                padding: "3px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                handleGoMap(meeting);
                              }}
                            >
                              {loadingMeetingImage === meeting.meeting_id ? (
                                <Spinner
                                  animation="border"
                                  variant="dark"
                                  size="sm"
                                />
                              ) : (
                                <FaMapMarkerAlt
                                  style={{ color: "red",
                                  marginLeft: "10px" ,fontSize: "20px" }}
                                />
                              )}
                        
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{ textAlign: "center" }}>No Record</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </>
                    )}
                  </tbody>
                </Table>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="current">
              <div className="table-responsive">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Company Name</th>
                      <th>Company Tin</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th>Contact Person</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                      <th>Remarks</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {meetingList?.length > 0 ? (
                      meetingList?.map((meeting, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{meeting.comp_name}</td>
                          <td>{meeting.comp_tin}</td>
                          <td>{meeting.cont_email}</td>
                          <td>{JSON.parse(meeting?.cont_mobile)}</td>
                          <td>{meeting.cont_person}</td>
                          <td>
                            {moment(meeting.meeting_st_date).format(
                              "DD/MM/YYYY hh:mm A"
                            )}
                          </td>
                          <td>
                            {meeting.meeting_end_date
                              ? moment(meeting.meeting_end_date).format(
                                "DD/MM/YYYY hh:mm A"
                              )
                              : " "}
                          </td>
                          <td>{meeting.meet_remarks}</td>
                          <td>
                            <span
                              style={{
                                width: "35px",
                                height: "30px",
                                padding: "3px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                getMeetingImage(meeting);
                              }}
                            >
                              {loadingMeetingImage === meeting.meeting_id ? (
                                <Spinner
                                  animation="border"
                                  variant="dark"
                                  size="sm"
                                />
                              ) : (
                                <FaRegEye
                                  style={{ color: "white", fontSize: "20px" }}
                                />
                              )}
                         
                            </span>
                            <span
                              style={{
                                width: "35px",
                                height: "30px",
                                padding: "3px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                handleGoMap(meeting);
                              }}
                            >
                              {loadingMeetingImage === meeting.meeting_id ? (
                                <Spinner
                                  animation="border"
                                  variant="dark"
                                  size="sm"
                                />
                              ) : (
                                <FaMapMarkerAlt
                                  style={{ color: "red",
                                  marginLeft: "10px" ,fontSize: "20px" }}
                                />
                              )}
                        
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{ textAlign: "center" }}>No Record</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                      </>
                    )}
                  </tbody>
                </Table>
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey="upcoming">
              <div className="table-responsive">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Company Name</th>
                      <th>Company Tin</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th>Contact Person</th>
                      <th>Start Time</th>
                      <th>Purpose</th>
                    </tr>
                  </thead>
                  <tbody>
                    {meetingList?.length > 0 ? (
                      meetingList?.map((meeting, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{meeting.comp_name}</td>
                          <td>{meeting.comp_tin}</td>
                          <td>{meeting.cont_email}</td>
                          <td>{JSON.parse(meeting?.cont_mobile)}</td>
                          <td>{meeting.cont_person}</td>
                          <td>
                            {moment(meeting.meeting_st_date).format(
                              "DD/MM/YYYY hh:mm A"
                            )}
                          </td>
                          <td>{meeting.meet_purpose}</td>
                        </tr>
                      ))
                    ) : (
                      <>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{ textAlign: "center" }}>No Record</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </>
                    )}
                  </tbody>
                </Table>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
      <Modal show={show} onHide={handleClose} size="xl">
        <div
          style={{ background: "#453d61", color: "#6afcf9", padding: "15px" }}
        >
          <div
            className="modal_header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h4 style={{ color: "white", margin: "0px" }}>View Images</h4>
            <span onClick={handleClose}>
              <IoClose
                style={{
                  fontSize: "30px",
                  cursor: "pointer",
                  color: "white",
                }}
              />

            </span>
          </div>
          <hr />

          <h3>{modaldata?.comp_name}</h3>
          <p className="m-0">
            <span style={{ color: "white" }}>Email :-</span>{" "}
            {modaldata?.cont_email}
          </p>
          <p className="m-0">
            <span style={{ color: "white" }}>Mobile :-</span>{" "}
            {modaldata?.cont_mobile}
          </p>
          <p>
            <span style={{ color: "white" }}>Meeting Purpose :-</span>{" "}
            {modaldata?.meet_purpose}
          </p>
          <p></p>
          <div className="viewtable">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Narration</th>
                  <th>Image</th>
                </tr>
              </thead>
              <tbody>
                {viewmodaldata?.length > 0 ? (
                  viewmodaldata &&
                  viewmodaldata?.map((ele, ind) => (
                    <>
                      <tr>
                        <td style={{ verticalAlign: "middle" }}>{ind + 1}</td>
                        <td style={{ verticalAlign: "middle" }}>
                          {ele.narration}
                        </td>
                        <td>
                          <img
                            src={ele?.file_url}
                            alt="img"
                            width={100}
                            height={100}
                            style={{ marginRight: "20px" }}
                            onClick={() => {
                              handleShowImage();
                              setImagePreview(ele?.file_url);
                            }}
                          />
                        </td>
                      </tr>
                    </>
                  ))
                ) : (
                  <>
                    <td></td>
                    <td className="text-center">No Record</td>
                    <td></td>
                  </>
                )}
              </tbody>
            </Table>
          </div>

          <hr />
          <button style={{ float: "right" }} onClick={handleClose}>
            Close
          </button>
        </div>
      </Modal>

      <Modal show={showImage} onHide={handleCloseImage} size="md">
        <div style={{ background: "#172b4c", padding: "20px" }}>
          <div
            className="modal_header"
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h4 style={{ color: "white", margin: "0px" }}>Images Preview</h4>
            <span onClick={handleCloseImage}>
              <IoClose
                style={{
                  fontSize: "30px",
                  cursor: "pointer",
                  color: "white",
                }}
              />
            </span>
          </div>
          {console.log("imagePreview", imagePreview)}
          <img
            src={imagePreview}
            alt="img"
            style={{
              width: "100%",
              height: "500px",
              marginRight: "20px",
              border: "2px solid #172b4c",
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ViewModal;
