import React from "react";
import { FcBusinessman } from "react-icons/fc";
import { IoClose } from "react-icons/io5";
import { CiLogout } from "react-icons/ci";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
const Navbar = ({ active, setActive }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));

  const navigate = useNavigate();
  const handlelogout = () => {
    Swal.fire({
      html: "<span style='color: #6afcf9'>Are you sure? want to Logout </span>",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
      customClass: {
        popup: "custom-swal-modal",
        content: "custom-swal-content",
        confirmButton: "custom-swal-confirm-button",
        cancelButton: "custom-swal-cancel-button",
      },
      allowHtml: true,
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("userData");
        navigate("/login");
      }
    });
  };

  return (
    <div className="container">
      <div className="heading p-2 d-flex justify-content-between align-items-center">
        <h1 className="d-flex align-items-center gap-3">
          <img
            src={userData?.data?.comp_data?.logo}
            alt={userData?.data.comp_data?.comp_name}
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              border: "2px solid #6afcf9",
            }}
            onClick={() => navigate("/home")}
          />
          <span style={{ fontSize: "22px", color: "#6afcf9" }}>
            {userData?.data?.comp_data?.comp_name}
          </span>
        </h1>

        <div className="btn-logout d-flex ">
          <div style={{ marginTop: "10px" }}>
            {userData?.data?.comp_data.comp_type == 2 ? (
              <div>
                {/* <Link
                  to="/home"
                  style={{
                    color: "#6afcf9",
                    marginRight: "20px",
                    textDecorationLine: "none",
                  }}
                >
                  Home
                </Link> */}
                {
                  userData?.data?.user_data?.role_id == 2 ?
                    <Link
                      to="/group"
                      style={{ color: "#6afcf9", textDecorationLine: "none" }}
                    >
                      Group
                    </Link> : ""
                }
                {
                  userData?.data?.user_data?.role_id == 2 ?
                    <Link
                      to="/ExpenseCategory"
                      style={{ color: "#6afcf9", textDecorationLine: "none", marginLeft: "20px" }}
                    >
                      ExpenseCat
                    </Link> : ""
                }
                {
                  userData?.data?.user_data?.role_id == 2 ?
                    <Link
                      to="/expense"
                      style={{ color: "#6afcf9", textDecorationLine: "none", marginLeft: "20px" }}
                    >
                      Expense
                    </Link> : ""
                }
                {
                  userData?.data?.user_data?.role_id == 2 ?
                    <Link
                      to="/receipt"
                      style={{ color: "#6afcf9", textDecorationLine: "none", marginLeft: "20px" }}
                    >
                      Receipt
                    </Link> : ""
                }

              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className="profile ms-2"
            style={{
              border: "1px solid #6afcf9",
              height: "40px",
              width: "40px",
              borderRadius: "50%",
              display: "grid",
              placeItems: "center",
            }}
          >
            <FcBusinessman
              style={{ fontSize: "35px", cursor: "pointer" }}
              onClick={() => setActive(!active)}
            />
            <div className={`user-details ${active ? "active" : ""}`}>
              <div
                className="d-flex justify-content-between p-2 mb-2"
                style={{ width: "100%" }}
              >
                <button
                  style={{
                    borderRadius: "5px",
                    borderRadius: "5px",
                    marginLeft: "5px",
                    fontSize: "14px",
                    height: "35px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => setActive(!active)}
                >
                  <IoClose style={{ fontSize: "25px" }} />
                </button>
                <button
                  style={{
                    borderRadius: "5px",
                    borderRadius: "5px",
                    marginLeft: "5px",
                    fontSize: "14px",
                    height: "35px",
                  }}
                  onClick={handlelogout}
                >
                  Logout <CiLogout />
                </button>
              </div>
              <div
                className="d-flex align-items-center gap-2"
                style={{
                  padding: "5px",
                  borderRadius: "5px",
                  boxShadow: "0 0 2px #6afcf9",
                }}
              >
                <img
                  src={userData?.data?.comp_data?.logo}
                  alt={userData?.data.comp_data?.comp_name}
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    border: "2px solid #6afcf9",
                    textAlign: "center",
                  }}
                />
                <h4 style={{ fontSize: "18px" }}>
                  {userData?.data?.comp_data?.comp_name}
                </h4>
              </div>
              <div className="p-2">
                <div>
                  <p className="name">
                    <span style={{ fontSize: "18px" }}>Name: </span>
                    <span>{userData?.data?.user_data?.fname}</span>{" "}
                    <span>{userData?.data?.user_data?.lname}</span>
                  </p>
                  <p className="name">
                    <span style={{ fontSize: "18px" }}>Role: </span>
                    {/* <span>Admin</span> */}
                    {
                      userData?.data?.user_data?.role_id == 2 ?
                        <span>Admin</span> :
                        <span>Manager</span>
                    }
                  </p>
                  <p className="name">
                    <span style={{ fontSize: "18px" }}>Username: </span>
                    <span>{userData?.data?.user_data?.username}</span>
                  </p>
                  <p className="name">
                    <span style={{ fontSize: "18px" }}>Mobile: </span>
                    <span>{userData?.data?.user_data?.mobile}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
