import { Snackbar, Typography } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { FaRegEye } from "react-icons/fa";
import { FiFilter } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { TfiBackLeft } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { BASE_URL } from "../../config";
import "../Home/Home.css";
import Appbar from "../Layouts/Appbar";

const Receipt = () => {
  const navigate = useNavigate();
  const [navbarStyle, setNavbarStyle] = useState({
    backgroundColor: "#172b4c",
    borderBottom: "none",
  });
  const [active, setActive] = useState(false);

  const [amt, setAmt] = useState("");
  const [desc, setDesc] = useState("");

  const [selectedData, setSelectedData] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [reasonErrorMsg, setReasonErrorMsg] = useState();
  const [searchedUser, setSearchedUser] = useState();
  const [pageloader, setPageloader] = useState(false);
  const [submit, setSubmit] = useState(false);

  const [expenseData, setExpenseData] = useState([]);
  const [cancleStatus, setCancleStatus] = useState("");
  console.log("cancleStatus", cancleStatus)
  const initialState = {
    amount: "",
    cust_name: "",
    rec_date: "",
    is_active: ""

  };
  const [formInputs, setFormInputs] = useState(initialState);

  //modal
  const [showApprove, setShowApprove] = useState(false);

  const handleCloseApprove = () => {
    setShowApprove(false);
    setAmt("");
    setSelectedData("");
    setDesc("");
    setErrorMsg("");
    setReasonErrorMsg("");
  };

  const handleShowApprove = (m) => {
    setShowApprove(true);
    setAmt(m.amount);
    setDesc(m.reason);
    setSelectedData(m);
  };
  const [showReject, setShowReject] = useState(false);
  const handleCloseReject = () => {
    setShowReject(false);
    setAmt("");
    setSelectedData("");
    setDesc("");
    setErrorMsg("");
    setReasonErrorMsg("");
    setCancleStatus("")
  };
  const handleShowReject = (m) => {
    setShowReject(true);
    setSelectedData(m);
    setCancleStatus("")
  };
  const [showDetails, setShowDetails] = useState(false);
  const [expDetails, setExpDetails] = useState([]);
  console.log("expDetails", expDetails)
  const handleCloseDetails = () => {
    setShowDetails(false);


  };
  const handleShowDetails = (data) => {
    setShowDetails(true);
    setExpDetails(data)

  };

  const userData = JSON.parse(localStorage.getItem("userData"));

  const changeStatus = async (value) => {
    setReasonErrorMsg("");

    console.log("value", value)
    console.log("cancleStatus", cancleStatus)
    if (!desc && value != 2) {
      setReasonErrorMsg("Reason is required");
      return;
    }
    setSubmit(true);
    try {
      const payload = {
        rec_id: selectedData.rec_id,
        reason: desc || "",
        status: value || cancleStatus
      };
      const axiosConfig = {
        headers: {
          Authorization: "bearer " + userData?.token,
        },
      };
      const { data } = await axios.post(
        `${BASE_URL}/receipt/changeStatusReceipt`,
        payload,
        axiosConfig
      );
      setErrorMsg({
        color: "#3f8037",
        msg: data.data,
      });
      setTimeout(() => {
        if (value === 2) {
          handleCloseApprove();
        } else {
          handleCloseReject();
        }
        setSubmit(false);
      }, 2000);
      getlist(false);
      setCancleStatus("")
    } catch (error) {
      setErrorMsg({
        color: "#ad4343",
        msg: error.response.data.data.message,
      });
      setSubmit(false);
      setCancleStatus("")
    }
  };

  const getlist = async (isFilter = true) => {
    setPageloader(true);
    let json = { paginate: 1 };
    if (isFilter) {
      json = {
        ...json,
        ...formInputs,
      };
    }
    const res = await api
      .listReceipt(userData, json)
      .then((res) => {
        setExpenseData(res.data.data.list);
        setPageloader(false);
      })
      .catch((err) => {
        setPageloader(false);
      });
  };
  useEffect(() => {
    getlist();
  }, []);

  //filter
  const [filterVisible, setFiltersVisible] = useState(false);
  function handleFilterVisibility() {
    if (filterVisible) {
      setFiltersVisible(false);
      setFormInputs(initialState);
    } else {
      setFiltersVisible(true);
    }
  }
  function handleInputs(e) {
    setFormInputs({ ...formInputs, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = 0;

      if (scrollPosition > threshold) {
        setNavbarStyle({
          backgroundColor: "#172b4c",
          borderBottom: "1px solid #6afcf9",
        });
      } else {
        setNavbarStyle({
          backgroundColor: "#172b4c",
          borderBottom: "none",
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="home expense">
      <div className="navbars" style={navbarStyle}>
        <Appbar active={active} setActive={setActive} />
      </div>
      <div className="viewmodal">
        <div>
          <button onClick={() => navigate("/home")}>
            <TfiBackLeft /> Back to Home
          </button>
          <button
            style={{ float: "right", marginBottom: "10px" }}
            onClick={(e) => handleFilterVisibility()}
          >
            <FiFilter />
          </button>
          {filterVisible && (
            <div className="row mt-3">
              <div className="col-md-3">
                <label htmlFor="username">Customer Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="cust_name"
                  name="cust_name"
                  value={formInputs.cust_name}
                  onChange={(e) => handleInputs(e)}
                />

              </div>

              <div className={"col-md-2"}>
                <label htmlFor="status">Receipt Status</label>
                <select
                  className="form-select"
                  id="is_active"
                  name="is_active"
                  value={formInputs.is_active}
                  onChange={(e) => {
                    setFormInputs({
                      ...formInputs,
                      is_active: e.target.value,
                    });
                  }}
                >
                  {[
                    { id: "", name: "All" },
                    { id: 1, name: "Receipt Generated" },
                    { id: 2, name: "Accepted" },
                    { id: 3, name: "Rejected " },
                    { id: 4, name: "Canceled" },
                  ].map((v, i) => (
                    <option
                      key={i}
                      value={v.id}
                      className={v.id === 0 ? "bg-lightgrey" : ""}
                    >
                      {v.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-2">
                <label htmlFor="mobile">Receipt Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="rec_date"
                  value={formInputs.rec_date}
                  onChange={(e) => handleInputs(e)}
                  max={moment().format("YYYY-MM-DD")}
                />
              </div>
              <div className="col-md-4 mt-4">
                <button
                  className="btn"
                  type="submit"
                  disabled={pageloader}
                  onClick={(e) => {
                    e.preventDefault();
                    setPageloader(true);
                    getlist(true);
                  }}
                  style={{
                    borderColor: "#6afcf9",
                    fontSize: "14px",
                    color: "#6afcf9",
                  }}
                >
                  {pageloader && (
                    <span className="spinner-border spinner-border-sm me-2"></span>
                  )}
                  Search
                </button>
                <button
                  className="btn rounded-3 px-3 ms-2"
                  type="button"
                  onClick={(e) => {
                    setFormInputs(initialState);
                    getlist(false, 1);
                    setSearchedUser("");
                  }}
                  style={{
                    borderColor: "white",
                    fontSize: "14px",
                    color: "white",
                  }}
                >
                  Clear Filters
                </button>
              </div>
            </div>
          )}
        </div>
        <hr />

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={errorMsg?.msg}
          color={errorMsg?.color}
          //  onClose={handleClose}
          sx={{ "& .MuiPaper-root": { backgroundColor: errorMsg?.color } }}
          autoHideDuration={3000}
          message={errorMsg?.msg}
        />
        <div className="table-responsive">
          <Table>
            <thead>
              <tr>
                <th>Salesman Name</th>
                <th>Customer Name</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Reference No</th>
                <th>Reason</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>

              {
                expenseData.length > 0 ?
                  <>
                    {expenseData.map((m, i) => (
                      <tr>
                        <td onClick={(e) => handleShowDetails(m)}>{m?.user_name}</td>
                        <td onClick={(e) => handleShowDetails(m)}>{m?.cust_name}</td>
                        <td onClick={(e) => handleShowDetails(m)} style={{ textAlign: "end" }}>{m.amount} <span style={{ fontSize: "12px" }}>{`(${m.currency})`}</span> </td>
                        <td onClick={(e) => handleShowDetails(m)}>
                          {m.is_active == 1 ? (
                            <span
                              style={{
                                background: "green",
                                color: "white",
                                border: "none",
                                padding: "5px 10px"
                              }}
                              className="  rounded "
                              role="button"
                            >
                              Receipt Generated
                            </span>
                          ) : m.is_active == 2 ? (
                            <span
                              style={{
                                background: "blue",
                                color: "white",
                                border: "none",
                                padding: "5px 10px"
                              }}
                              className="  rounded "
                              role="button"
                            >
                              Accepted
                            </span>
                          ) : m.is_active == 3 ? (
                            <span
                              style={{
                                background: "red",
                                color: "white",
                                border: "none",
                                padding: "5px 10px"
                              }}
                              className="  rounded  "
                              role="button"
                            >
                              Rejected
                            </span>
                          ) : (
                            <span
                              style={{
                                background: "red",
                                color: "white",
                                border: "none",
                                padding: "5px 10px"
                              }}
                              className="  rounded "
                              role="button"
                            >
                              Canceled
                            </span>
                          )}
                        </td>
                        <td className="" onClick={(e) => handleShowDetails(m)}>{m.ref_no}</td>

                        <td onClick={(e) => handleShowDetails(m)}>
                          {" "}
                          <span style={{ whiteSpace: "nowrap" }} title={m.reason}>
                            {m.reason == null
                              ? "-"
                              : m.reason.length > 35
                                ? m.reason.toString().substring(0, 35) + "..."
                                : m.reason}
                          </span>
                        </td>
                        <td onClick={(e) => handleShowDetails(m)}> {moment(m.rec_date).format("DD-MM-YY")}</td>
                        <td>
                          <span
                            className={`py-0 link-info mx-1 ${m.is_active == 1 ? "" : "d-none"
                              }`}
                            style={{
                              width: "35px",
                              height: "30px",
                              padding: "3px",
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              handleShowApprove(m);
                            }}
                          >
                            <AiOutlineCheck
                              style={{ color: "white", fontSize: "20px" }}
                            />
                          </span>
                          <span
                            className={`py-0 link-info mx-1 ${m.is_active == 1 ? "" : "d-none"
                              }`}
                            style={{
                              width: "35px",
                              height: "30px",
                              padding: "3px",
                              cursor: "pointer",
                            }}
                            onClick={(e) => handleShowReject(m)}
                          >
                            <AiOutlineClose
                              style={{ color: "white", fontSize: "20px" }}
                            />
                          </span>
                          <span
                            className={`py-0 link-info mx-1 ${m.is_active == 1 ? "" : "d-none"
                              }`}
                            style={{
                              width: "35px",
                              height: "30px",
                              padding: "3px",
                              cursor: "pointer",
                            }}
                            onClick={(e) => handleShowDetails(m)}
                          >
                            <FaRegEye
                              style={{ color: "white", fontSize: "20px" }}
                            />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </> :
                  <>
                    {pageloader && (
                      <tr>
                        <td colSpan="8" className="text-center">
                          {pageloader ? "Loading..." : "No Records"}
                        </td>
                      </tr>
                    )}
                  </>
              }
            </tbody>
          </Table>
        </div>


        <div className="footer p-2">
          <h4
            className="footheader text-center mt-2"
            style={{ fontSize: "16px" }}
          >
            Powered by:{" "}
            <a
              href="https://www.cabbagesoft.com"
              target="#blank"
              style={{ textDecoration: "none", color: "#6afcf9" }}
            >
              Cabbagesoft Technologies
            </a>
          </h4>
        </div>
      </div>
      {/* reject */}
      <Modal show={showReject} onHide={handleCloseReject}>
        <div
          style={{ background: "#453d61", color: "#6afcf9", padding: "15px" }}
        >
          <div
            className="modal_header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h4 style={{ color: "white", margin: "0px" }}>Reject / Cancel</h4>
            <span onClick={handleCloseReject}>
              <IoClose
                style={{
                  fontSize: "30px",
                  cursor: "pointer",
                  color: "white",
                }}
              />
            </span>
          </div>
          <hr />
          <Modal.Body>
            <h5> Are You sure, you want to Reject / Cancel?</h5>
            <select name="" id="" value={cancleStatus} onChange={(e) => setCancleStatus(e.target.value)} style={{ padding: "5px", width: "100%", borderRadius: "5px", outline: "none" }}>
              <option value="">Please Select</option>
              <option value="3">Reject</option>
              <option value="4">Cancel</option>
            </select>

            <div className="col">
              <label htmlFor="mobile">Reason</label>
              <span className="text-danger">*</span>
              <textarea
                rows={4}
                type="text"
                className="form-control"
                id="amount"
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
              />
              {reasonErrorMsg && (
                <Typography color={"#e15f5f"} fontSize={14}>
                  {reasonErrorMsg}
                </Typography>
              )}
            </div>
          </Modal.Body>
          <hr />
          <div className="foot mt-3 d-flex justify-content-end gap-2">
            <button
              className="btn btn-secondary"
              onClick={handleCloseReject}
              disabled={submit}
            >
              Cancel
            </button>
            <button
              onClick={(e) => changeStatus()}
              style={{ borderRadius: "5px" }}
              disabled={submit}
            >
              {submit && (
                <span className="spinner-border spinner-border-sm me-2"></span>
              )}
              Save
            </button>
          </div>
        </div>
      </Modal>

      {/* Approve */}
      <Modal show={showApprove} onHide={handleCloseApprove}>
        <div
          style={{ background: "#453d61", color: "#6afcf9", padding: "15px" }}
        >
          <div
            className="modal_header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h4 style={{ color: "white", margin: "0px" }}>
              Accept Amount
            </h4>
            <span onClick={handleCloseApprove}>
              <IoClose
                style={{
                  fontSize: "30px",
                  cursor: "pointer",
                  color: "white",
                }}
              />
            </span>
          </div>
          <hr />
          <Modal.Body>
            <h5>Hope you received this amount ! Please accept the receipt.</h5>
            <div className="col mb-2">
              <label htmlFor="mobile">
                Amount
              </label>
              <input
                type="text"
                className="form-control"
                id="amount"
                value={amt}
                disabled
                onChange={(e) => setAmt(e.target.value)}
              />
            </div>

          </Modal.Body>
          <hr />
          <div className="foot mt-3 d-flex justify-content-end gap-2">
            <button
              className="btn btn-secondary"
              onClick={handleCloseApprove}
              disabled={submit}
            >
              Cancel
            </button>
            <button
              onClick={(e) => changeStatus(2)}
              style={{ borderRadius: "5px" }}
              disabled={submit}
            >
              {submit && (
                <span className="spinner-border spinner-border-sm me-2"></span>
              )}
              Accept
            </button>
          </div>
        </div>
      </Modal>

      {/* Receipt Details */}
      <Modal show={showDetails} onHide={handleCloseDetails}>
        <div
          style={{ background: "#453d61", color: "#6afcf9", padding: "15px" }}
        >
          <div
            className="modal_header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h4 style={{ color: "white", margin: "0px" }}>
              Receipt Details
            </h4>
            <span onClick={handleCloseDetails}>
              <IoClose
                style={{
                  fontSize: "30px",
                  cursor: "pointer",
                  color: "white",
                }}
              />
            </span>
          </div>
          <hr />
          <Modal.Body>
            <div className="table-responsive expenseDetail">
              <p className="m-0"> <span >Salesman Name </span>: <span style={{ color: "white" }}> {expDetails?.user_name}</span></p>
              <p className="m-0"> <span >Customer Name </span>: <span style={{ color: "white" }}> {expDetails?.cust_name}</span></p>
              <p className="m-0"><span >Amount </span>: <span style={{ color: "white" }}> {expDetails?.amount} {`(${expDetails?.currency})`}</span></p>
              <p className="m-0"><span >Refrence No </span>: <span style={{ color: "white" }}> {expDetails?.ref_no} </span></p>
              <p className="m-0"><span >Date </span>: <span style={{ color: "white" }}>{moment(expDetails?.rec_date).format("DD-MM-YYYY")}</span> </p>
              {
                expDetails.is_active == 3 || expDetails.is_active == 4 ? <p className="m-0"><span >Reason </span>: <span style={{ color: "white" }}> {expDetails?.reason}</span></p> : ""
              }

              <p className="m-0"><span >Description </span>: <span style={{ color: "white" }}> {expDetails?.desc}</span></p>
            </div>
          </Modal.Body>
          <hr />
          <div className="foot mt-3 d-flex justify-content-end gap-2">
            <button
              className="btn btn-secondary"
              onClick={handleCloseDetails}
              disabled={submit}
            >
              Close
            </button>

          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Receipt;
