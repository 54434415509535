import { IoClose } from "react-icons/io5";
import "../Home/Home.css";
function GroupView(props) {
    return (
        <div className="addmodal">
            <div
                className="modal_header"
                style={{ display: "flex", justifyContent: "space-between" }}
            >
                <h4 style={{ color: "white", margin: "0px" }}>
                    View Group
                </h4>
                <span onClick={props.hide}>
                    <IoClose
                        style={{
                            fontSize: "30px",
                            cursor: "pointer",
                            color: "white",
                        }}
                    />
                </span>
            </div>
            <div className="container-fluid p-4" id="productAdd">
                <div className="row gy-3">
                    <div className="col-md">
                        <label htmlFor="grp_name">Group Name</label>
                        <input
                            disabled
                            type="text"
                            className="form-control"
                            id="grp_name"
                            value={props.id?.grp_name}
                        />
                    </div>
                    <div className="col-12 mt-4">
                        <button
                            onClick={props.hide}
                            style={{ float: "right" }}
                            type="button"
                            className="uomModalCancel"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GroupView;
