import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { default as bike, default as iconImg } from "./Login/biker_image.png";
import moment from "moment";

const Location = () => {
  let location = useLocation();
  let data = location.state?.data;
  let points = JSON.parse(data.st_lat_lng);

  console.log("data", data);
  const [selectedMarker1, setSelectedMarker1] = useState(false);

  const containerStyle = {
    width: "100wh",
    height: "90vh",
  };
  const customMarkerIcon = {
    url: iconImg,
    scaledSize: new window.google.maps.Size(40, 40),
  };

  return (
    <div className="map p-2">
      <div className="mapbox mt-4">
        {points ? (
          <>
            <GoogleMap
              center={{
                lat: parseFloat(points.lat),
                lng: parseFloat(points.lng),
              }}
              mapContainerStyle={containerStyle}
              zoom={20}
            >
              <Marker
                position={{
                  lat: parseFloat(points.lat),
                  lng: parseFloat(points.lng),
                }}
                onClick={() => setSelectedMarker1(true)}
                icon={customMarkerIcon}
              />
              {selectedMarker1 && (
                <InfoWindow
                  position={{
                    lat: parseFloat(points.lat),
                    lng: parseFloat(points.lng),
                  }}
                  onCloseClick={() => setSelectedMarker1(false)}
                  options={{
                    pixelOffset: new window.google.maps.Size(0, -40),
                  }}
                >
                  <div style={{ color: "black" }}>
                    <h5>Company Name: {data?.comp_name}</h5>
                    <p style={{ color: "black" }}>
                      Contact person: {data?.cont_person}
                    </p>
                    <p style={{ color: "black" }}>
                      Start Trip:{" "}
                      {data?.meeting_st_date
                        ? moment(data?.meeting_st_date).format(
                            "DD/MM/YYYY hh:mm A"
                          )
                        : "N/A"}
                    </p>
                    <p style={{ color: "black" }}>
                      End Trip:{" "}
                      {data?.meeting_end_date
                        ? moment(data?.meeting_end_date).format(
                            "DD/MM/YYYY hh:mm A"
                          )
                        : "N/A"}
                    </p>
                  </div>
                </InfoWindow>
              )}
            </GoogleMap>
          </>
        ) : (
          <div
            className="offline-message text-center"
            style={{
              height: "100px",
              padding: "20px 0",
            }}
          >
            <h1 className="mt-4">Location is not available!</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default Location;
