import React, { useContext, useEffect, useRef, useState } from "react";
import "./Home.css";
import Cardbox from "../Cardbox";
import { IoMdAddCircleOutline } from "react-icons/io";
import { IoAdd } from "react-icons/io5";
import { CiLogout } from "react-icons/ci";
import Addmodal from "../Addmodal";
import Swal from "sweetalert2";
import { FiRefreshCcw } from "react-icons/fi";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import { infinity } from "ldrs";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FcBusinessman } from "react-icons/fc";
import api from "../../api";
import { IoClose } from "react-icons/io5";
import { FaMapMarkerAlt } from "react-icons/fa";
import Appbar from "../Layouts//Appbar";
import ReactSelect from "react-select";
import { FiFilter } from "react-icons/fi";
import { OptionContext } from "../../App";
import { getOption } from "../../utils";

const Home = () => {
  infinity.register();

  const groupOption = useContext(OptionContext);

  const [show, setShow] = useState(false);
  const [pageloader, setPageloader] = useState(false);
  const [deliveryData, setDeliveryData] = useState([]);
  const refereshRef = useRef(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const [added, setAdded] = useState(true);
  const [deleted, setDeleted] = useState(true);
  const [edited, setEdited] = useState(true);
  const [active, setActive] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [pltdata, setPltdata] = useState([]);

  const initialState = {
    fname: "",
    // grp_id: "",
    grp_data: [],
  };
  const [formInputs, setFormInputs] = useState(initialState);
  const nevigate = useNavigate();
  // const admindata = localStorage.setItem("Admindata");
  const [navbarStyle, setNavbarStyle] = useState({
    backgroundColor: "#172b4c",
    borderBottom: "none",
  });
  // ----tooltip-----
  useEffect(() => {
    tippy(refereshRef.current, {
      content: "Refresh",
      placement: "top",
    });
  }, []);
  // ------scroll navbar------
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = 0;

      if (scrollPosition > threshold) {
        setNavbarStyle({
          backgroundColor: "#172b4c",
          borderBottom: "1px solid #6afcf9",
        });
      } else {
        setNavbarStyle({
          backgroundColor: "#172b4c",
          borderBottom: "none",
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //filter
  const [filterVisible, setFiltersVisible] = useState(false);
  function handleFilterVisibility() {
    if (filterVisible) {
      setFiltersVisible(false);
      setFormInputs(initialState);
    } else {
      setFiltersVisible(true);
    }
  }

  const [selectedVal, setSelectedVal] = useState([]);
  function handleInputs(e, name) {
    if (name === "grp_data") {
      const selectedValues = e.map((option) => option.value);
      setFormInputs({ ...formInputs, [name]: selectedValues });

      setSelectedVal(e);
    } else {
      setFormInputs({ ...formInputs, [name]: e.target.value });
    }
  }
  // function handleInputs(e, name) {

  //   setFormInputs({ ...formInputs, [name]: e.target.value });

  // }

  // -----api fetch List-----
  const userData = JSON.parse(localStorage.getItem("userData"));

  const getlist = async (isFilter = true) => {
    setPageloader(true);
    let payload = {};
    if (isFilter) {
      payload = {
        ...formInputs,
      };
    }
    const res = await api
      .listUsers(userData, payload)
      .then((res) => {
        setDeliveryData(res.data.data.list);
        setPageloader(false);
      })
      .catch((err) => {
        setPageloader(false);
      });
  };
  useEffect(() => {
    getlist();
    const interval = setInterval(getlist, 60000);
    return () => clearInterval(interval);
  }, [added, deleted, edited, refresh]);

  // -----------handle logout---------
  const handlelogout = () => {
    Swal.fire({
      html: "<span style='color: #6afcf9'>Are you sure? want to Logout </span>",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
      customClass: {
        popup: "custom-swal-modal",
        content: "custom-swal-content",
        confirmButton: "custom-swal-confirm-button",
        cancelButton: "custom-swal-cancel-button",
      },
      allowHtml: true,
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("userData");
        navigate("/login");
      }
    });
  };

  const [groupList, setGroupList] = useState([])
  const getGroupList = async (isFilter = true) => {
    let json = { paginate: 0, is_active: 1 };
    const res = await api
      .listGroupmaster(userData, json)
      .then((res) => {

        const options = res.data?.data?.list?.map(({ grp_id, grp_name }) => ({
          value: grp_id,
          label: grp_name,
        }));
        setGroupList(options)
      })
      .catch((err) => {
        setPageloader(false);
      });
  };
  useEffect(() => {
    getGroupList();
  }, []);

  return (
    <div className="home">
      <div className="navbars" style={navbarStyle}>
        <Appbar active={active} setActive={setActive} />
      </div>
      <div className="container">
        {/* <div className="list_name d-flex gap-4 ms-3 align-items-center justify-content-between"> */}
        <div className="row">
          <div className="col-md-6">
            <div className="d-flex gap-2">
              {userData?.data?.comp_data?.comp_type == 2 ? (
                <h5> Salesman</h5>
              ) : (
                <h5> Delivery Boys</h5>
              )}
              <span
                ref={refereshRef}
                onClick={() => setRefresh(!refresh)}
                style={{
                  borderRadius: "50%",
                  width: "28px",
                  height: "28px",
                  display: "grid",
                  placeItems: "center",
                  padding: "0x !important",
                  border: "2px solid #6afcf9",
                  cursor: "pointer",
                }}
              >
                <FiRefreshCcw style={{ fontSize: "15px" }} />
              </span>
            </div>
          </div>
          {/* <div className="col-md-6"></div> */}

          <div className="col-md-6 float-end">
            <div className="d-flex justify-content-end">
              <button
                style={{
                  borderRadius: "5px",
                  fontSize: "14px",
                  marginRight: "10px",
                }}
                onClick={(e) => handleFilterVisibility()}
              >
                <FiFilter />
              </button>
              <button
                style={{
                  borderRadius: "5px",
                  fontSize: "14px",
                  marginRight: "10px",
                }}
                className=""
                onClick={() => {
                  nevigate("/maps");
                }}
              >
                View Map <FaMapMarkerAlt />
              </button>
              {
                userData?.data?.user_data?.role_id == 2 ?
                  <button
                    style={{
                      borderRadius: "5px",
                      fontSize: "14px",
                    }}
                    onClick={handleShow}
                    className="website"
                  >
                    Add User <IoAdd />
                  </button> : ""
              }

              <button
                style={{
                  borderRadius: "5px",
                  fontSize: "14px",
                }}
                onClick={handleShow}
                className="app"
              >
                Add New <IoAdd />
              </button>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
      {filterVisible && (
        <div className="container">
          <div className="row">
            {userData?.data?.comp_data?.comp_type == 2 && (
              <div className="col-md-3 ">
                <label htmlFor="grp_data">Group </label>
                <br />
                <ReactSelect
                  options={getOption()}
                  isMulti
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={selectedVal}
                  name="grp_data"
                  onChange={(e) => handleInputs(e, "grp_data")}
                />
                {/* <select name="grp_id" className="select" id="grp_id" onChange={(e) => handleInputs(e, "grp_id")} value={formInputs.grp_id}>
                  <option value="">Select</option>
                  {
                    getOption()?.map((ele, ind) => (
                      <option value={ele.value}>
                        {ele.label}
                      </option>
                    ))
                  }

                </select> */}
              </div>
            )}
            <div className="col-md-3">
              <label htmlFor="">Name</label>
              <br />
              <input
                type="text"
                placeholder="Filter by Name"
                name="fname"
                value={formInputs.fname}
                onChange={(e) => handleInputs(e, "fname")}
                style={{
                  margin: "10px ,0px",
                  border: "1px solid #6afcf9",
                  background: "transparent",
                  borderRadius: "5px",
                  outline: "none",
                  width: "220px",
                  padding: "5px",
                  color: "#6afcf9",
                  marginRight: "10px",
                }}
              />
            </div>
            <div className="col-md-3 mt-4">
              <button
                className="btn"
                type="submit"
                disabled={pageloader}
                onClick={(e) => {
                  e.preventDefault();
                  setPageloader(true);
                  getlist(true);
                }}
                style={{
                  borderColor: "#6afcf9",
                  fontSize: "14px",
                  color: "#6afcf9",
                }}
              >
                {pageloader && (
                  <span className="spinner-border spinner-border-sm me-2"></span>
                )}
                Search
              </button>
              <button
                className="btn rounded-3 px-3 ms-2"
                type="button"
                onClick={() => {
                  getlist(false);
                  setFormInputs(initialState);
                  setSelectedVal([]);
                }}
                style={{
                  borderColor: "white",
                  fontSize: "14px",
                  color: "white",
                }}
              >
                Clear Filters
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="container">
        <div className="main_wrapper pt-3 pb-3">
          {pageloader ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "400px",
              }}
            >
              <l-infinity
                size="100"
                stroke="4"
                stroke-length="0.15"
                bg-opacity="0.1"
                speed="1.3"
                color="#6afcf9"
              ></l-infinity>
            </div>
          ) : (
            <div className="cardmain">
              {deliveryData.length > 0 ? (
                deliveryData.map((ele, ind) => (
                  <Cardbox
                    data={ele}
                    pltdata={pltdata}
                    setDeleted={setDeleted}
                    deleted={deleted}
                    setEdited={setEdited}
                    edited={edited}
                    getlist={getlist}
                    groupList={groupList || []}
                  />
                ))
              ) : (
                <div
                  className="text-center w-100"
                  style={{
                    height: "400px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h2 className="text-center">No record</h2>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="footer p-2">
          <h4
            className="footheader text-center mt-2"
            style={{ fontSize: "16px" }}
          >
            Powered by:{" "}
            <a
              href="https://www.cabbagesoft.com"
              target="#blank"
              style={{ textDecoration: "none", color: "#6afcf9" }}
            >
              Cabbagesoft Technologies
            </a>
          </h4>
        </div>
      </div>

      <Addmodal
        show={show}
        setAdded={setAdded}
        groupList={groupList}
        added={added}
        handleClose={handleClose}
        handleShow={handleClose}
      />
    </div>
  );
};

export default Home;
