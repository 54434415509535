export const getOption = () => {

    const userData = JSON.parse(localStorage.getItem("userData"));
    const options = userData?.data?.grp_data?.map(({ grp_id, grp_name }) => ({
        value: grp_id,
        label: grp_name,
    }));


    return options;


}

