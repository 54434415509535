import { Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { CiEdit } from "react-icons/ci";
import { FiFilter } from "react-icons/fi";
import { TfiBackLeft } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import "../Home/Home.css";
import Appbar from "../Layouts/Appbar";
import ExpCatAdd from "./ExpCatAdd";
import ExpCatEdit from "./ExpCatEdit";
import ExpCatView from "./ExpCatView";

const ExpCatList = () => {
  const navigate = useNavigate();
  const [navbarStyle, setNavbarStyle] = useState({
    backgroundColor: "#172b4c",
    borderBottom: "none",
  });
  const [active, setActive] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [pageloader, setPageloader] = useState(false);
  const [expenseData, setExpenseData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);

  const initialState = {
    grp_name: "",
    is_active: ""

  };

  const [formInputs, setFormInputs] = useState(initialState);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showId, setShowId] = useState(0);
  const [data, setData] = useState("");
  const userData = JSON.parse(localStorage.getItem("userData"));
  async function changeStatus(exp_cat_id, is_active) {
    const payload = { exp_cat_id, is_active };
    let target = window.event.target;
    let oldText = target.innerText;
    target.innerText = "Updating...";
    try {
      const res = await api.ChangeStatusExpCategory(userData, payload);
      setErrorMsg("");
      getlist();
    } catch (err) {
      // setErrorMsg(err.responseJSON.message);
      setTimeout(function () {
        setErrorMsg("");
      }, 3000)
    } finally {
      target.innerText = oldText;
    }
  }
  const getlist = async (isFilter = true) => {
    console.log("formInputs", formInputs)
    setPageloader(true);
    let json = { paginate: 0 };
    const res = await api
      .listExpCategory(userData, json)
      .then((res) => {
        setExpenseData(res.data.data.list);
        setPageloader(false);
        setTotalPage(res.data.data?.count)
      })
      .catch((err) => {
        setPageloader(false);
      });
  };
  useEffect(() => {
    getlist();
  }, []);

  //filter
  const [filterVisible, setFiltersVisible] = useState(false);
  function handleFilterVisibility() {
    if (filterVisible) {
      setFiltersVisible(false);
      setFormInputs(initialState);
    } else {
      setFiltersVisible(true);
    }
  }
  function handleInputs(e) {
    setFormInputs({ ...formInputs, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = 0;

      if (scrollPosition > threshold) {
        setNavbarStyle({
          backgroundColor: "#172b4c",
          borderBottom: "1px solid #6afcf9",
        });
      } else {
        setNavbarStyle({
          backgroundColor: "#172b4c",
          borderBottom: "none",
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="home expense">
      <div className="navbars" style={navbarStyle}>
        <Appbar active={active} setActive={setActive} />
      </div>
      <div className="viewmodal">
        <div>
          <button onClick={() => navigate("/home")}>
            <TfiBackLeft /> Back to Home
          </button>
          {/* <button
            style={{ float: "right", marginBottom: "10px" }}
            onClick={(e) => handleFilterVisibility()}
          >
            <FiFilter />
          </button> */}
          <button style={{ float: "right", marginBottom: "10px", marginRight: "20px" }}
            onClick={() => {
              handleShow();
              setShowId(1);
            }}
          >
            Add Exp Cat
          </button>
          {filterVisible && (
            <div className="row mt-3">
              <div className="col-md-3">
                <label htmlFor="username">Group Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="grp_name"
                  name="grp_name"
                  value={formInputs.grp_name}
                  onChange={(e) => handleInputs(e)}
                />
              </div>
              <div className="col-md-3">
                <label htmlFor="is_active">Status</label>
                <select
                  className="form-select"
                  id="is_active"
                  name="is_active"
                  value={formInputs.is_active}
                  onChange={(e) => handleInputs(e)}
                >
                  {[
                    { id: "", name: "All" },
                    { id: 1, name: "Active" },
                    { id: 0, name: "In-Active" },
                  ].map((v, i) => (
                    <option key={i} value={v.id}>
                      {v.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-4 mt-4">
                <button
                  className="btn"
                  type="submit"
                  disabled={pageloader}
                  onClick={(e) => {
                    e.preventDefault();
                    setPageloader(true);
                    getlist(true);
                  }}
                  style={{
                    borderColor: "#6afcf9",
                    fontSize: "14px",
                    color: "#6afcf9",
                  }}
                >
                  {pageloader && (
                    <span className="spinner-border spinner-border-sm me-2"></span>
                  )}
                  Search
                </button>
                <button
                  className="btn rounded-3 px-3 ms-2"
                  type="button"
                  onClick={(e) => {
                    setFormInputs(initialState);
                    getlist(false, 1);
                  }}
                  style={{
                    borderColor: "white",
                    fontSize: "14px",
                    color: "white",
                  }}
                >
                  Clear Filters
                </button>
              </div>
            </div>
          )}
        </div>
        <hr />

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={errorMsg?.msg}
          color={errorMsg?.color}
          //  onClose={handleClose}
          sx={{ "& .MuiPaper-root": { backgroundColor: errorMsg?.color } }}
          autoHideDuration={3000}
          message={errorMsg?.msg}
        />
        <div className="table-responsive">
          <Table>
            <thead>
              <tr>
                <th>Expense Category Name</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>

              {
                expenseData.length > 0 ?
                  <>
                    {expenseData.map((m, i) => (
                      <tr>
                        <td
                          onClick={() => {
                            setData(m);
                            handleShow();
                            setShowId(3);
                          }}

                        >{m?.exp_cat_name}</td>
                        <td>
                          {m.is_active ? (
                            <span
                              className="badge text-bg-success"
                              role="button"
                              onClick={() => changeStatus(m.exp_cat_id, 0)}
                            >
                              Active
                            </span>
                          ) : (
                            <span
                              className="badge text-bg-danger"
                              role="button"
                              onClick={() => changeStatus(m.exp_cat_id, 1)}
                            >
                              In-Active
                            </span>
                          )}
                        </td>
                        <td>
                          <span
                            className={`py-0 link-info mx-1 ${m.is_active == 1 ? "" : "d-none"
                              }`}
                            style={{
                              width: "35px",
                              height: "30px",
                              padding: "3px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleShow()
                              setShowId(2)
                              setData(m)

                            }}

                          >
                            <CiEdit
                              style={{ color: "white", fontSize: "20px" }}
                            />
                          </span>


                        </td>
                      </tr>
                    ))}
                  </> :
                  <>
                    {pageloader && (
                      <tr>
                        <td colSpan="8" className="text-center">
                          {pageloader ? "Loading..." : "No Records"}
                        </td>
                      </tr>
                    )}
                  </>
              }
            </tbody>
          </Table>
          {/* <div className="row">
            <div className="col">
              <Pagination
                nPages={Math.ceil(totalPage / 10)}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div> */}
        </div>


        <div className="footer p-2">
          <h4
            className="footheader text-center mt-2"
            style={{ fontSize: "16px" }}
          >
            Powered by:{" "}
            <a
              href="https://www.cabbagesoft.com"
              target="#blank"
              style={{ textDecoration: "none", color: "#6afcf9" }}
            >
              Cabbagesoft Technologies
            </a>
          </h4>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        keyboard={true}
        backdrop="static"
      >
        {showId == 1 ? (
          <ExpCatAdd id={data} list={getlist} hide={handleClose} />
        ) : showId == 2 ? (
          <ExpCatEdit id={data} list={getlist} hide={handleClose} />
        ) : showId == 3 ? (
          <ExpCatView id={data} list={getlist} hide={handleClose} />
        ) : null}
      </Modal>
    </div >
  );
};

export default ExpCatList;
