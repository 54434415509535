import React, { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import "./Login.css";
import imgbiker from "./biker_image.png";
import { MdLogin } from "react-icons/md";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";
import { BASE_URL } from "../../config";
import api from "../../api";

const Login = () => {
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const validationSchema = yup.object().shape({
    username: yup.string().required("Username is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(4, "Password should be greater than 4 Digit")
      .max(12, "Password should less than  12 Digit"),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      envinfo: "web",
      versioninfo: "1.0",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoader(true);
      await axios
        .post(BASE_URL + "/login", values)
        .then((res) => {
          setSuccess(res.data.message);
          const userData = {
            token: res.data.data.token,
            data: res.data.data,
          };
          localStorage.setItem("userData", JSON.stringify(userData));        
         
          setLoader(false);
          navigate("/home");
          setTimeout(() => {
            setSuccess("");
          }, 2000);
        })
        .catch((err) => {
          setError(err.response.data.data.message);
          setLoader(false);
          setTimeout(() => {
            setError("");
          }, 2000);
        });
    },
  });

  return (
    <>
      <div className="login">
        <div className=" d-flex align-items-center justify-content-center mb-4">
          <div className="login_wrapper">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="left p-4">
                  <img src={imgbiker} alt="" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-center">
                <div className="right p-4">
                  <h2>Welcome to Trackingz</h2>
                  <div className="login_feilds">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="username mt-3">
                        <label htmlFor="username">UserName *</label>
                        <br />
                        <input
                          type="text"
                          id="username"
                          name="username"
                          placeholder="Username@123"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.username}
                          autoComplete="off"
                        />
                        <p className="error">
                          {formik.touched.username && formik.errors.username}
                        </p>
                      </div>
                      <div className="password">
                        <label htmlFor="password">Password *</label>
                        <br />
                        <div style={{ position: "relative" }}>
                          <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            placeholder="123456789"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            autoComplete="off"
                          />
                          <button
                            type="button"
                            className="password-toggle"
                            style={{
                              border: "none",
                              width: "25px",
                              background: "transparent",
                              position: "absolute",
                              right: "40px",
                            }}
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? (
                              <FaRegEyeSlash
                                style={{ fontSize: "25px", color: "#6afcf9" }}
                              />
                            ) : (
                              <FaRegEye
                                style={{ fontSize: "25px", color: "#6afcf9" }}
                              />
                            )}
                          </button>
                        </div>

                        <p className="error">
                          {formik.touched.password && formik.errors.password}
                        </p>
                      </div>
                      <button
                        type="submit"
                        className="mt-3 btn"
                        disabled={loader}
                      >
                        {loader ? (
                          <Spinner
                            animation="border"
                            variant="light"
                            size="sm"
                          />
                        ) : (
                          <>
                            Login <MdLogin />
                          </>
                        )}
                      </button>

                      <div style={{ height: "70px" }}>
                        {(error || success) && (
                          <div
                            className={`alert alert-${error ? "danger" : "success"
                              } py-2 small mt-2`}
                            role="alert"
                            style={{ width: "300px" }}
                          >
                            {error || success || ""}
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="loginfooter p-1 d-flex justify-content-center align-items-end"
          style={{
            height: "100px",
          }}
        >
          <h4
            className="footheader text-center m-0"
            style={{ fontSize: "16px", color: "#6afcf9" }}
          >
            Powered by:{" "}
            <a
              href="https://www.cabbagesoft.com"
              target="#blank"
              style={{ textDecoration: "none", color: "#6afcf9" }}
            >
              Cabbagesoft Technologies
            </a>
          </h4>
        </div>
      </div>
    </>
  );
};

export default Login;
