// api.js

import axios from "axios";
import { BASE_URL } from "./config";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

const api = {
  cancelUser: (userData, userId) => {
    return axiosInstance.post(
      "/user/cancelUser",
      { user_id: userId, is_active: "0" },
      {
        headers: {
          Authorization: "bearer " + userData.token,
        },
      }
    );
  },
  getTripLocation: (tampLink) => {
    return axiosInstance.post("/trip/tripLocation", { tamp_link: tampLink });
  },
  editUser: (userData, payload) => {
    return axiosInstance.post("/user/editUser", payload, {
      headers: {
        Authorization: "bearer " + userData.token,
      },
    });
  },
  addUser: (userData, payload) => {
    return axiosInstance.post("/user/createUser", payload, {
      headers: {
        Authorization: "bearer " + userData.token,
      },
    });
  },
  listUsers: (userData, payload) => {
    payload = { ...payload, is_active: 1, paginate: 0 };
    return axiosInstance.post("/user/listUser", payload, {
      headers: {
        Authorization: "bearer " + userData?.token,
      },
    });
  },
  tripLocationAll: (userData) => {
    return axiosInstance.post("trip/tripLocationAll", "", {
      headers: {
        Authorization: "bearer " + userData?.token,
      },
    });
  },
  listMeeting: (userData, payload) => {
    payload = { ...payload, paginate: 0 };
    return axiosInstance.post("meeting/listMeeting", payload, {
      headers: {
        Authorization: "bearer " + userData?.token,
      },
    });
  },
  tripLocationHistory: (userData, payload) => {
    return axiosInstance.post("trip/tripLocationHistory", payload, {
      headers: {
        Authorization: "bearer " + userData?.token,
      },
    });
  },
  listExpense: (userData, payload) => {
    payload = { ...payload, is_active: 1, paginate: 0 };   //role_id: 3
    return axiosInstance.post("expense/listExpense", payload, {
      headers: {
        Authorization: "bearer " + userData?.token,
      },
    });
  },
  listReceipt: (userData, payload) => {
    payload = { ...payload, paginate: 0 };  //role_id: 3
    return axiosInstance.post("receipt/listReceipt", payload, {
      headers: {
        Authorization: "bearer " + userData?.token,
      },
    });
  },
  listGroupmaster: (userData, payload) => {
    payload = { ...payload };  //role_id: 3
    return axiosInstance.post("group/listGroup", payload, {
      headers: {
        Authorization: "bearer " + userData?.token,
      },
    });
  },
  ChangeListGroupStatus: (userData, payload) => {
    payload = { ...payload };  //role_id: 3
    return axiosInstance.post("group/cancelGroup", payload, {
      headers: {
        Authorization: "bearer " + userData?.token,
      },
    });
  },
  listMeetingDetail: (userData, payload) => {
    payload = { ...payload, paginate: 0 };
    return axiosInstance.post("meeting/listMeetingDetail", payload, {
      headers: {
        Authorization: "bearer " + userData?.token,
      },
    });
  },
  listGroup: (userData, payload) => {
    payload = { is_active: 1, paginate: 0 };
    return axiosInstance.post("group/listGroup", payload, {
      headers: {
        Authorization: "bearer " + userData?.token,
      },
    });
  },
  CreateGroup: (userData, payload) => {
    return axiosInstance.post("group/createGroup", payload, {
      headers: {
        Authorization: "bearer " + userData?.token,
      },
    });
  },
  EditGroup: (userData, payload) => {
    return axiosInstance.post("group/editGroup", payload, {
      headers: {
        Authorization: "bearer " + userData?.token,
      },
    });
  },
  listExpCategory: (userData, payload) => {
    return axiosInstance.post("expCategory/listExpCategory", payload, {
      headers: {
        Authorization: "bearer " + userData?.token,
      },
    });
  },
  EditExpCategory: (userData, payload) => {
    return axiosInstance.post("expCategory/editExpCategory", payload, {
      headers: {
        Authorization: "bearer " + userData?.token,
      },
    });
  },
  AddExpCategory: (userData, payload) => {
    return axiosInstance.post("expCategory/createExpCategory", payload, {
      headers: {
        Authorization: "bearer " + userData?.token,
      },
    });
  },
  ChangeStatusExpCategory: (userData, payload) => {
    return axiosInstance.post("expCategory/cancelExpCategory", payload, {
      headers: {
        Authorization: "bearer " + userData?.token,
      },
    });
  }
};

export default api;
