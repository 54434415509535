// import "./style.css"

function Pagination({nPages, currentPage, setCurrentPage}) {
    const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

    function nextPage() {
        if (currentPage !== nPages) {
            setCurrentPage(currentPage + 1)
        }
    }

    function prevPage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    function generatePages(currentPage, pageNumbers) {
        if (pageNumbers.length < 8) {
            return (<>
                {pageNumbers.map((pageNumber) => (
                    <li key={`page_` + pageNumber}
                        className={`page-item ${currentPage == pageNumber ? 'active' : ''} m-1`}>
                        <a className="page-link rounded" href="#"
                            onClick={() => setCurrentPage(pageNumber)}>{pageNumber}</a>
                    </li>
                ))}
            </>)
        } else {
            if (currentPage < 5) {
                return (<>
                    {pageNumbers.slice(0, 5).map((pageNumber) => (
                        <li key={`page_` + pageNumber}
                            className={`page-item ${currentPage == pageNumber ? 'active' : ''} m-1`}>
                            <a className="page-link rounded" href="#"
                                onClick={() => setCurrentPage(pageNumber)}>{pageNumber}</a>
                        </li>
                    ))}

                    <li key={`page_right`} className={`page-item m-1`}>
                        <span className="page-link rounded pe-none">...</span>
                    </li>
    
                    <li key={`page_${pageNumbers.length}`}
                        className={`page-item ${currentPage == pageNumbers.length ? 'active' : ''} m-1`}>
                        <a className="page-link rounded" href="#"
                        onClick={() => setCurrentPage(pageNumbers.length)}>{pageNumbers.length}</a>
                    </li>
                </>)
            } else if (currentPage > pageNumbers.length - 4) {
                return (<>
                    <li key={`page_1`}
                        className={`page-item ${currentPage == 1 ? 'active' : ''} m-1`}>
                        <a className="page-link rounded" href="#"
                        onClick={() => setCurrentPage(1)}>1</a>
                    </li>

                    <li key={`page_left`} className={`page-item m-1`}>
                        <span className="page-link rounded pe-none">...</span>
                    </li>

                    {pageNumbers.slice(pageNumbers.length - 5, pageNumbers.length).map((pageNumber) => (
                        <li key={`page_` + pageNumber}
                            className={`page-item ${currentPage == pageNumber ? 'active' : ''} m-1`}>
                            <a className="page-link rounded" href="#"
                                onClick={() => setCurrentPage(pageNumber)}>{pageNumber}</a>
                        </li>
                    ))}
                </>)
            } else {
                return (<>
                    <li key={`page_1`}
                        className={`page-item ${currentPage == 1 ? 'active' : ''} m-1`}>
                        <a className="page-link rounded" href="#"
                        onClick={() => setCurrentPage(1)}>1</a>
                    </li>

                    <li key={`page_left`} className={`page-item m-1`}>
                        <span className="page-link rounded pe-none">...</span>
                    </li>

                    {[currentPage - 1, currentPage, currentPage + 1].map((pageNumber) => (
                        <li key={`page_` + pageNumber}
                            className={`page-item ${currentPage == pageNumber ? 'active' : ''} m-1`}>
                            <a className="page-link rounded" href="#"
                                onClick={() => setCurrentPage(pageNumber)}>{pageNumber}</a>
                        </li>
                    ))}

                    <li key={`page_right`} className={`page-item m-1`}>
                        <span className="page-link rounded pe-none">...</span>
                    </li>
    
                    <li key={`page_${pageNumbers.length}`}
                        className={`page-item ${currentPage == pageNumbers.length ? 'active' : ''} m-1`}>
                        <a className="page-link rounded" href="#"
                        onClick={() => setCurrentPage(pageNumbers.length)}>{pageNumbers.length}</a>
                    </li>
                </>)
            }
        }
    }

    return (
        <>
            <nav id="paginate">
                <ul className="pagination justify-content-end">
                    <li className="align-self-center me-3">
                        {currentPage}-{nPages} of items
                    </li>
                    <li className={`page-item ${currentPage == 1 ? 'disabled' : ''} m-1`}>
                        <a className="page-link rounded" href="#" onClick={prevPage}>&lt;</a>
                    </li>
                    {generatePages(currentPage, pageNumbers)}
                    <li className={`page-item ${currentPage == nPages ? 'disabled' : ''} m-1`}>
                        <a className="page-link rounded" href="#" onClick={nextPage}>&gt;</a>
                    </li>
                </ul>
            </nav>
        </>
    );
}

export default Pagination;