// validationSchemas.js

import * as yup from "yup";

export const addUserSchema = (userData) =>
  yup.object().shape({
    fname: yup.string().required("First Name is required"),
    lname: yup.string().required("Last Name is required"),
    username: yup.string().required("User Name is required"),
    grp_id: yup.string().when(() => {
      if (userData?.data?.comp_data?.comp_type == 2) {
        return yup.string().required("Group is required");
      }
    }),
    grp_data: yup.array().when([], () => {
      // if (userData?.data?.comp_data?.comp_type == 2) {
      //   return yup.array().min(1, "Group is required");
      // }
    }),
    password: yup
      .string()
      .required("Password is required")
      .min(4, "Password should be minimum 4 Digit")
      .max(12, "Password should maximum  12 Digit"),
    mobile: yup
      .string()
      .required("Mobile is required")
      .min(9, "Mobile should be at least 9 characters long")
      .max(15, "Mobile should not exceed 15 characters"),
    sync_interval: yup
      .number()
      .required("Sync status is required")
      .when(() => {
        if (userData?.data?.comp_data?.comp_type == 2) {
          return yup
            .number()
            .required("Sync status is required")
            .min(5, "Interval range should be 5 to 300 Seconds")
            .max(300, "Interval range should be 5 to 300 Seconds");
        }
      }),
  });
export const editUserSchema = (userData) =>
  yup.object().shape({
    fname: yup.string().required("First Name is required"),
    lname: yup.string().required("Last Name is required"),
    username: yup.string().required("User Name is required"),
    grp_data: yup.array().when([], () => {
      // if (userData?.data?.comp_data?.comp_type === 2) {
      //   return yup.array().min(1, "Group is required");
      // }
    }),
    // grp_id: yup.string().when(() => {
    //   if (userData?.data?.comp_data?.comp_type == 2) {
    //     return yup.string().required("Group is required");
    //   }
    // }),
    grp_id: yup.string().nullable().when(() => {
      if (userData?.data?.comp_data?.comp_type == 2) {
        return yup.string().required("Group is required");
      }
    }),

    password: yup
      .string()
      .min(4, "Password should be minimum 4 Digit")
      .max(12, "Password should maximum  12 Digit"),
    mobile: yup
      .string()
      .required("Mobile is required")
      .min(9, "Mobile should be at least 9 characters long")
      .max(15, "Mobile should not exceed 15 characters"),
    sync_interval: yup
      .number()
      .required("Sync status is required")
      .when(() => {
        if (userData?.data?.comp_data?.comp_type == 2) {
          return yup
            .number()
            .required("Sync status is required")
            .min(5, "Interval range should be 5 to 300 Seconds")
            .max(300, "Interval range should be 5 to 300 Seconds");
        }
      }),
  });
