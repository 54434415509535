import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { IoClose } from "react-icons/io5";
import Select from "react-select";
import api from "../api";
import "./Home/Home.css";
import { editUserSchema } from "./Validation/UserSchemas";
import { OptionContext } from "../App";
import { getOption } from "../utils";

function Editmodal({ show, handleClose, setEdited, edited, data, getlist, groupList }) {
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);

  const userData = JSON.parse(localStorage.getItem("userData"));
  const [val, setVal] = useState([]);
  useEffect(() => {
    if (data && data.grp_data) {
      const datanew = data.grp_data.map((ele, ind) => ({
        value: ele.grp_id,
        label: ele.grp_name,
      }));

      setVal(datanew);
    } else {
      setVal([]);
    }
  }, [show]);
  const formik = useFormik({
    initialValues: {
      fname: data?.fname,
      lname: data?.lname,
      username: data?.username,
      mobile: data?.mobile,
      user_id: data?.user_id,
      sync_interval: data?.sync_interval,
      role_id: data?.role_id,
      isReceipt: data?.isReceipt,
      grp_data: data.grp_data.map(g => g.grp_id),
      grp_id: data?.grp_id,
    },

    validationSchema: editUserSchema(userData),
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => {
      const errors = {};

      if (values.role_id == 4 && values.grp_data.length == 0 && userData?.data?.comp_data?.comp_type == 2) {
        errors.grp_data = "Access Group is required";
      }


      return errors;
    },
    onSubmit: async (values, { resetForm }) => {
      const payload = values.password
        ? { ...values, password: values.password }
        : values;
      setLoader(true);
      try {
        const res = await api.editUser(userData, values);
        setSuccess(res.data.message);
        // setEdited(!edited);

        setLoader(false);
        setTimeout(() => {
          setSuccess("");
        }, 2000);
      } catch (err) {
        setLoader(false);
        setError(err.response.data.data.message);
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    },
  });

  const handleClosemodal = (e) => {
    formik.resetForm();
    e.preventDefault();
    getlist();
    handleClose();
  };
  // const handleChange = (selectedOptions) => {
  //   const selectedValues = selectedOptions.map((option) => option.value);
  //   formik.setFieldValue("grp_data", selectedValues);
  //   setVal(selectedOptions);
  // };


  const handleChange = (selectedOption) => {
    const selectedValues = selectedOption
      ? Array.isArray(selectedOption)
        ? selectedOption.map((option) => option.value)
        : [selectedOption.value]
      : [];
    formik.setFieldValue("grp_data", selectedValues);

    setVal(selectedOption);
  };


  return (
    <>
      <div style={{ background: "red" }}>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <div className="addmodal">
            <div
              className="modal_header"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              {/* <h4 style={{ color: "white", margin: "0px" }}>
                {userData?.data?.comp_data?.comp_type == 2
                  ? "Edit Salesman "
                  : "Edit Delivery Boy"}
              </h4> */}
              <h4 style={{ color: "white", margin: "0px" }}>
                Edit User
              </h4>

              <span onClick={handleClosemodal}>
                <IoClose
                  style={{
                    fontSize: "30px",
                    cursor: "pointer",
                    color: "white",
                  }}
                />
              </span>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="modal_body mt-4">
                <div className="wrapper_feilds">
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="fname">First Name *</label>
                      <br />
                      <input
                        type="text"
                        name="fname"
                        id="fname"
                        className="input"
                        autoComplete="off"
                        {...formik.getFieldProps("fname")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") e.preventDefault();
                        }}
                      />
                      {formik.errors.fname ? (
                        <div className="text-danger small">
                          {formik.errors.fname}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="lname">Last Name *</label>
                      <br />
                      <input
                        type="text"
                        name="lname"
                        id="lname"
                        className="input"
                        autoComplete="off"
                        {...formik.getFieldProps("lname")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") e.preventDefault();
                        }}
                      />
                      {formik.errors.lname ? (
                        <div className="text-danger small">
                          {formik.errors.lname}
                        </div>
                      ) : null}
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="userName">User Name *</label>
                      <br />
                      <input
                        type="text"
                        name="username"
                        id="username"
                        className="input"
                        autoComplete="off"
                        {...formik.getFieldProps("username")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") e.preventDefault();
                        }}
                      />
                      {formik.errors.username ? (
                        <div className="text-danger small">
                          {formik.errors.username}
                        </div>
                      ) : null}
                    </div>

                    <div className="col-md-6 mt-2">
                      <label htmlFor="password">New Password </label>
                      <br />
                      <input
                        type="text"
                        name="password"
                        id="password"
                        autoComplete="off"
                        className="input"
                        {...formik.getFieldProps("password")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") e.preventDefault();
                        }}
                      />
                      {formik.errors.password ? (
                        <div className="text-danger small">
                          {formik.errors.password}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-6 mt-2">
                      <label htmlFor="password">
                        Sync Interval{" "}
                        {userData?.data?.comp_data?.comp_type == 2
                          ? "(Sec) "
                          : ""}
                        *
                      </label>
                      <br />
                      {userData?.data?.comp_data?.comp_type == 2 ? (
                        <input
                          type="text"
                          className="input"
                          maxLength={4}
                          onKeyDown={(e) => {
                            if (
                              !(
                                e.key === "Enter" ||
                                e.key === "Backspace" ||
                                e.key === "Tab" ||
                                /^[0-9]+$/.test(e.key)
                              )
                            ) {
                              e.preventDefault();
                            }
                            if (e.key === "Enter") e.preventDefault();
                          }}
                          {...formik.getFieldProps("sync_interval")}
                        />
                      ) : (
                        <select
                          name="sync_interval"
                          id="sync_interval"
                          className="select"
                          {...formik.getFieldProps("sync_interval")}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") e.preventDefault();
                          }}
                        >
                          <option value="">Select</option>
                          <option value="30">0.5 Min</option>
                          <option value="60">1 Min</option>
                          <option value="120">2 Min</option>
                          <option value="180">3 Min</option>
                          <option value="240">4 Min</option>
                          <option value="300">5 Min</option>
                        </select>
                      )}

                      {formik.errors.sync_interval ? (
                        <div className="text-danger small">
                          {formik.errors.sync_interval}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-6 mt-2">
                      <label htmlFor="password">Mobile *</label>
                      <br />
                      <input
                        type="text"
                        name="mobile"
                        id="mobile"
                        className="input"
                        autoComplete="off"
                        {...formik.getFieldProps("mobile")}
                        onKeyDown={(e) => {
                          if (
                            !/^\d+$/.test(e.key) &&
                            e.keyCode !== 8 &&
                            e.keyCode !== 9 &&
                            e.keyCode !== 37 &&
                            e.keyCode !== 39 &&
                            e.keyCode !== 46
                          ) {
                            e.preventDefault();
                            if (e.key === "Enter") e.preventDefault();
                          }
                          if (e.target.value.length >= 15 && e.keyCode !== 8) {
                            e.preventDefault();
                          }
                        }}
                      />
                      {formik.errors.mobile ? (
                        <div className="text-danger small">
                          {formik.errors.mobile}
                        </div>
                      ) : null}
                    </div>

                    <div className="col-md-6 mt-2">
                      <label htmlFor="role">Role</label>
                      <br />
                      <select
                        name="role_id"
                        id="role_id"
                        className="select"
                        disabled
                        {...formik.getFieldProps("role_id")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") e.preventDefault();
                        }}
                      >
                        <option value="3">
                          {userData?.data?.comp_data?.comp_type == 2
                            ? "Salesman"
                            : "Delivery boy"}
                        </option>
                        {userData?.data?.comp_data?.comp_type == 2 ? <option value="4">Manager</option> : " "}

                      </select>
                    </div>

                    {
                      userData?.data?.comp_data?.comp_type == 2 ?
                        <div className="col-md-6 mt-2">
                          <label htmlFor="role">Receipt Access</label>
                          <br />
                          <select
                            name="isReceipt"
                            id="isReceipt"
                            className="select"
                            {...formik.getFieldProps("isReceipt")}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") e.preventDefault();
                            }}
                          >
                            <option value="0">No</option>
                            <option value="1">Yes</option>

                          </select>
                        </div>
                        : ""
                    }

                    {
                      userData?.data?.comp_data?.comp_type == 2 ?
                        <div className="col-md-6 mt-2">
                          <label htmlFor="role">Group *</label>
                          <br />
                          <select
                            name="grp_id"
                            id="grp_id"
                            className="select"
                            {...formik.getFieldProps("grp_id")}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") e.preventDefault();
                            }}
                          >
                            <option value="">
                              Select
                            </option>
                            {groupList?.map((ele, ind) => (
                              <option key={ind} value={ele.value}>
                                {ele.label}
                              </option>
                            ))}

                          </select>
                          {formik.errors.grp_id && (
                            <div className="text-danger small">
                              {formik.errors.grp_id}
                            </div>
                          )}
                        </div> : ""
                    }


                    {userData?.data?.comp_data?.comp_type == 2 && formik.values.role_id == 4 && (
                      <div className="col-md-6 mt-2">
                        <label htmlFor="grp_data">Access Group *</label>
                        <br />
                        <Select
                          options={groupList}
                          isMulti
                          name="colors"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={handleChange}
                          // value={formik.values.grp_data}
                          value={val}

                        // {...formik.getFieldProps("grp_data")}
                        />

                        {formik.errors.grp_data && (
                          <div className="text-danger small">
                            {formik.errors.grp_data}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {(error || success) && (
                    <div
                      className={`alert alert-${error ? "danger" : "success"
                        } py-2 small mt-2`}
                      role="alert"
                    >
                      {error || success || ""}
                    </div>
                  )}
                  <div className="foot mt-3 d-flex justify-content-end gap-2">
                    <button
                      className="btn btn-secondary"
                      onClick={handleClosemodal}
                    >
                      Cancel
                    </button>
                    <button
                      style={{ borderRadius: "5px" }}
                      type="submit"
                      disabled={loader}
                    >
                      {loader ? (
                        <Spinner animation="border" variant="light" size="sm" />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default Editmodal;
