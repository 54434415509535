import "bootstrap/dist/css/bootstrap.min.css";
import { createContext } from "react";
import { Navigate, BrowserRouter as Router, useRoutes } from "react-router-dom";
import "./App.css";
import Expense from "./Components/Expense";
import Home from "./Components/Home/Home";
import Receipt from "./Components/Receipt";
import Location from "./Components/Location";
import Login from "./Components/Login/Login";
import Allmap from "./Components/Map/Allmap";
import Map from "./Components/Map/Map";
import MapDetails from "./Components/Map/MapDetails";
import ViewModal from "./Components/ViewModal";
import GroupList from "./Components/Group";
import ExpCatList from "./Components/ExpenseCat";
export const OptionContext = createContext(null);

const AppRoutes = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));

  const routes = useRoutes([
    {
      path: "/login",
      element: userData?.token ? <Navigate to="/home" /> : <Login />,
    },
    {
      path: "/",
      element: <Navigate to="/login" />,
    },

    {
      path: "/home",
      element: userData?.token ? <Home /> : <Navigate to="/login" />,
    },
    {
      path: "/Maps",
      element: userData?.token ? <Allmap /> : <Navigate to="/login" />,
    },
    {
      path: "/View",
      element: userData?.token ? <ViewModal /> : <Navigate to="/login" />,
    },
    {
      path: "/MapDetails",
      element: userData?.token ? <MapDetails /> : <Navigate to="/login" />,
    },
    {
      path: "/expense",
      element: userData?.token ? <Expense /> : <Navigate to="/login" />,
    },
    {
      path: "/receipt",
      element: userData?.token ? <Receipt /> : <Navigate to="/login" />,
    },
    {
      path: "/group",
      element: userData?.token ? <GroupList /> : <Navigate to="/login" />,
    },
    {
      path: "/ExpenseCategory",
      element: userData?.token ? <ExpCatList /> : <Navigate to="/login" />,
    },
    {
      path: "/location",
      element: userData?.token ? <Location /> : <Navigate to="/login" />,
    },
    {
      path: "/map/:tamp_link",
      element: <Map />,
    },
  ]);

  return routes;
};

function App() {
  const userData = JSON.parse(localStorage.getItem("userData"));



  return (
    <OptionContext.Provider >
      <Router>
        <AppRoutes />
      </Router>
    </OptionContext.Provider>
  );
}

export default App;
