import { IoClose } from "react-icons/io5";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import api from "../../api";
import * as Yup from "yup";

function ExpCatEdit(props) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const userData = JSON.parse(localStorage.getItem("userData"));

    const groupFormik = useFormik({
        initialValues: {
            exp_cat_id: props?.id?.exp_cat_id,
            exp_cat_name: props?.id?.exp_cat_name,
        },
        validationSchema: Yup.object().shape({
            exp_cat_name: Yup.string()
                .min(3, "Expense Category name must be at least 3 characters")
                .max(50, "Expense Category name must be at most 50 characters")
                .required("Expense Category name is required"),
        }),
        onSubmit: async (values) => {
            setIsSubmitting(true);
            try {
                const response = await api.EditExpCategory(userData, values);
                setSuccessMessage(response.data.message);
                setErrorMessage("");
                props.list();
            } catch (error) {
                setErrorMessage(error.response.data.message || "An error occurred");
                setSuccessMessage("");
            } finally {
                setIsSubmitting(false);
            }
        },
        onReset: () => {
            setSuccessMessage("");
        },
    });

    useEffect(() => {
        const timer = setTimeout(() => {
            setSuccessMessage("");
            setErrorMessage("");
        }, 5000);
        return () => clearTimeout(timer);
    }, [successMessage, errorMessage]);

    return (
        <div className="addmodal">
            <div className="modal-header" style={{ display: "flex", justifyContent: "space-between" }}>
                <h4 style={{ color: "white", margin: "0px" }}>Edit Expense Category</h4>
                <span onClick={props.hide}>
                    <IoClose style={{ fontSize: "30px", cursor: "pointer", color: "white" }} />
                </span>
            </div>
            <div className="container-fluid p-4" id="productAdd">
                <form onSubmit={groupFormik.handleSubmit} onReset={groupFormik.handleReset} autoComplete="off">
                    <div className="row gy-3">
                        <div className="col-md">
                            <label htmlFor="exp_cat_name">Expense Category Name <span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                id="exp_cat_name"
                                {...groupFormik.getFieldProps("exp_cat_name")}
                            />
                            {groupFormik.errors.exp_cat_name && groupFormik.touched.exp_cat_name && (
                                <p className="">{groupFormik.errors.exp_cat_name}</p>
                            )}
                        </div>
                        {(errorMessage || successMessage) && (
                            <div className={`alert alert-${errorMessage ? "danger" : "success"} py-2 small`} role="alert">
                                {errorMessage || successMessage}
                            </div>
                        )}
                        <div className="col-12 mt-4 d-flex justify-content-end gap-2">
                            <button onClick={props.hide} type="button" className="uomModalCancel">
                                Cancel
                            </button>
                            <button type="submit" disabled={isSubmitting}>
                                {isSubmitting && <span className="spinner-border spinner-border-sm me-2"></span>}
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ExpCatEdit;
