import React, { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { json, useLocation, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { FaMapPin } from "react-icons/fa";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  Polyline,
} from "@react-google-maps/api";
import { GOOGLE_API_Key, INTERVAL_TIME } from "../../config";
import iconImg from "../Login/biker_image.png";
import iconImg1 from "../Login/iconmap.png";
import api from "../../api";
import moment from "moment";

const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
};

const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in km
  return distance;
};

const extractMarkerData = (markersData) => {
  let result = [];

  let filteredMarker = markersData?.filter((m) => {
    return (
      m.lat !== null &&
      m.lng !== null &&
      !isNaN(parseFloat(m.lat)) &&
      !isNaN(parseFloat(m.lng))
    );
  });

  let currentMarker = {};
  for (let i = 0; i < filteredMarker.length; i++) {
    const marker = filteredMarker[i];

    if (i == 0) {
      currentMarker = {
        lat: parseFloat(marker.lat),
        long: parseFloat(marker.lng),
      };
      result.push({
        ...marker,
        lat: parseFloat(marker.lat),
        lng: parseFloat(marker.lng),
      });
    } else {
      let lat1 = parseFloat(currentMarker.lat);
      let long1 = parseFloat(currentMarker.long);
      let lat2 = parseFloat(marker.lat);
      let long2 = parseFloat(marker.lng);
      if (calculateDistance(lat1, long1, lat2, long2) >= 0.1) {
        currentMarker = {
          lat: parseFloat(marker.lat),
          long: parseFloat(marker.lng),
        };
        result.push({
          ...marker,
          lat: parseFloat(marker.lat),
          lng: parseFloat(marker.lng),
        });
      }
    }
  }

  result = result.map((r) => {
    return { ...r, mLen: result.length };
  });

  return result;

  // index %
  //                     (INTERVAL_TIME /
  //                       parseInt(markersData?.user_data?.sync_interval)) ==
  //                     0
};

const MapDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data } = location.state;

  console.log("data", data);
  const [startDate, setStartDate] = useState(new Date());
  const [formattedDate, setFormattedDate] = useState(
    moment(new Date()).format("DD/MM/YYYY")
  );

  const [markersData, setMarkersData] = useState();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedMarker1, setSelectedMarker1] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 19.076, lng: 72.8777 });
  const [loading, setLoading] = useState(false);
  const [directions, setDirections] = useState(null);
  const [mapError, setMapError] = useState(null);

  useEffect(() => {
    const fetchTripLocationHistory = () => {
      setLoading(true);
      const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
      api
        .tripLocationHistory(userData, {
          user_id: data.user_id,
          trip_date: formattedStartDate,
        })
        .then((res) => {
          setMarkersData(res.data.data);
          setMapError();
        })
        .catch((error) => {
          console.error(error);
          setMapError("No Record available for this Date");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchTripLocationHistory();

    const interval = setInterval(fetchTripLocationHistory, 300000);

    return () => clearInterval(interval);
  }, [startDate, userData.user_id]);

  const containerStyle = {
    width: "100wh",
    height: "100vh",
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_Key,
  });

  const customMarkerIcon = {
    url: iconImg,
    scaledSize: new window.google.maps.Size(40, 40),
  };
  const customMarkerIcon1 = {
    url: iconImg1,
    scaledSize: new window.google.maps.Size(1, 1),
  };

  const [map, setMap] = useState(null);
  const onLoad = useCallback((map) => setMap(map), []);

  useEffect(() => {
    if (map && markersData) {
      const bounds = new window.google.maps.LatLngBounds();
      markersData?.trip_data?.trip_dtl.map((marker) => {
        if (
          marker.lat !== null &&
          marker.lng !== null &&
          !isNaN(parseFloat(marker.lat)) &&
          !isNaN(parseFloat(marker.lng))
        ) {
          bounds.extend({
            lat: parseFloat(marker.lat),
            lng: parseFloat(marker.lng),
          });
        }
      });
      map.fitBounds(bounds);
    }
  }, [map, markersData]);

  if (!isLoaded) {
    return (
      <div
        style={{
          background: "#172b4c",
          padding: "20px",
        }}
      >
        <button
          onClick={() => {
            window.location.reload();
          }}
        >
          Go Back
        </button>

        <div
          style={{
            background: "#172b4c",
            color: "#6afcf9",
            minHeight: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3> Error: Google Maps API not loaded.</h3>
        </div>
      </div>
    );
  }

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
    if (
      marker?.lat !== null &&
      marker?.lng !== null &&
      !isNaN(parseFloat(marker?.lat)) &&
      !isNaN(parseFloat(marker?.lng))
    ) {
      setMapCenter({
        lat: parseFloat(marker.lat),
        lng: parseFloat(marker.lng),
      });
    }
  };
  let positionnew = null;
  const handleMarkerClick1 = (marker) => {
    const position = JSON.parse(marker.st_lat_lng);
    setSelectedMarker1(marker);

    if (selectedMarker1) {
      positionnew = JSON.parse(selectedMarker1.st_lat_lng);
    }

    if (
      position?.lat !== null &&
      position?.lng !== null &&
      !isNaN(parseFloat(position?.lat)) &&
      !isNaN(parseFloat(position?.lng))
    ) {
      setMapCenter({
        lat: parseFloat(position.lat),
        lng: parseFloat(position.lng),
      });
    }
  };

  return (
    <div className="MapDetails">
      <button
        onClick={() => {
          navigate("/home");
        }}
      >
        Back
      </button>
      <div className="mb-4 mt-2">
        <h2>
          NAME :- {data?.fname} {data.lname}
        </h2>
        <p>MOBILE :- {data.mobile}</p>
        <span style={{ marginRight: "5px" }}>DATE :- </span>
        <DatePicker
          selected={startDate}
          onChange={(date) => {
            if (date <= new Date()) {
              setStartDate(date);
              setFormattedDate(moment(date).format("DD/MM/YYYY"));
            }
          }}
          dateFormat="dd/MM/yyyy"
          maxDate={new Date()}
        />
      </div>
      {mapError ? (
        <div
          style={{
            background: "#172b4c",
            color: "#6afcf9",
            minHeight: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3> Error: {mapError}</h3>
        </div>
      ) : (
        <>
          {loading ? (
            <div className="loader">Loading...</div>
          ) : (
            <div className="mapbox">
              <GoogleMap
                mapContainerStyle={containerStyle}
                zoom={10}
                onLoad={onLoad}
              >
                {extractMarkerData(markersData?.trip_data?.trip_dtl || []).map(
                  (marker, index) => (
                    <>
                      <Marker
                        key={index}
                        position={{
                          lat: parseFloat(marker.lat),
                          lng: parseFloat(marker.lng),
                        }}
                        icon={
                          index == 0 || marker.mLen - 1 == index
                            ? customMarkerIcon
                            : customMarkerIcon1
                        }
                        onClick={() => handleMarkerClick(marker)}
                      />
                    </>
                  )
                )}

                {markersData?.trip_data?.meeting_data.map((marker, index) => {
                  const position = JSON.parse(marker.st_lat_lng);
                  
                  return (
                    <Marker
                      key={index}
                      position={{
                        lat: parseFloat(position.lat),
                        lng: parseFloat(position.lng),
                      }}
                      onClick={() => handleMarkerClick1(marker)}
                    />
                  );
                })}

                {selectedMarker &&
                  selectedMarker.lat !== null &&
                  selectedMarker.lng !== null &&
                  !isNaN(parseFloat(selectedMarker.lat)) &&
                  !isNaN(parseFloat(selectedMarker.lng)) && (
                    <InfoWindow
                      position={{
                        lat: parseFloat(selectedMarker?.lat),
                        lng: parseFloat(selectedMarker?.lng),
                      }}
                      onCloseClick={() => setSelectedMarker(null)}
                      options={{
                        pixelOffset: new window.google.maps.Size(0, -40),
                      }}
                    >
                      <div style={{ color: "black" }}>
                        <h3>
                          {markersData?.user_data?.fname}{" "}
                          {markersData?.user_data?.lname}
                        </h3>
                        <p>{markersData?.user_data?.mobile}</p>
                        <p>
                          SYNC :-{" "}
                          {moment(selectedMarker?.sync_at).format(
                            "DD/MM/YYYY hh:mm A"
                          )}
                        </p>

                        {markersData?.trip_data?.trip_hdr
                          .filter(
                            (hdr) =>
                              hdr?.trip_hdr_id === selectedMarker?.trip_hdr_id
                          )
                          .map((hdr, index) => (
                            <div key={index}>
                              <p>
                                Start Trip:{" "}
                                {moment(hdr?.start_trip).format(
                                  "DD/MM/YYYY hh:mm A"
                                )}
                              </p>
                              <p>
                                End Trip:{" "}
                                {hdr?.end_trip
                                  ? moment(hdr?.end_trip).format(
                                      "DD/MM/YYYY hh:mm A"
                                    )
                                  : "NA"}
                              </p>
                            </div>
                          ))}
                      </div>
                    </InfoWindow>
                  )}
                {selectedMarker1 && (
                  <>
                    <InfoWindow
                      position={{
                        lat: parseFloat(positionnew?.lat),
                        lng: parseFloat(positionnew?.lng),
                      }}
                      onCloseClick={() => setSelectedMarker1(null)}
                      options={{
                        pixelOffset: new window.google.maps.Size(0, -40),
                      }}
                    >
                      <div style={{ color: "black" }}>
                        <h5>Company Name: {selectedMarker1?.comp_name}</h5>
                        <p>Contact person: {selectedMarker1?.cont_person}</p>
                        <p>
                          Start time:{" "}
                          {moment(selectedMarker1?.meeting_st_date).format(
                            "DD/MM/YYYY hh:mm A"
                          )}
                        </p>
                        <p>
                          End time:{" "}
                          {moment(selectedMarker1?.meeting_end_date).format(
                            "DD/MM/YYYY hh:mm A"
                          )}
                        </p>
                      </div>
                    </InfoWindow>
                  </>
                )}

                <Polyline
                  path={extractMarkerData(
                    markersData?.trip_data?.trip_dtl || []
                  ).map((m) => {
                    return { lat: m.lat, lng: m.lng };
                  })}
                  strokeColor="#000000"
                  strokeOpacity={1}
                  strokeWeight={10}
                />
              </GoogleMap>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MapDetails;
