import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import { infinity } from "ldrs";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api";
import { GOOGLE_API_Key } from "../../config";
import { default as bike, default as iconImg } from "../Login/biker_image.png";
import "./Map.css";
const Map = () => {
  infinity.register();
  let { tamp_link } = useParams();
  const [pageloader, setPageloader] = useState(false);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [userdata, setUserdata] = useState("");
  const [compdata, setCompdata] = useState("");
  const [tripdata, setTripdata] = useState("");
  const [trip_hdr, setTrip_hdr] = useState("");
  const [active_status, setActive_status] = useState("");
  const [isLocation, setIsLocation] = useState("");
  const [intervalId, setIntervalId] = useState(null);

  const [loading, setLoading] = useState(true);
  const [mapCenter, setMapCenter] = useState({ lat: latitude, lng: longitude });
  const navigate = useNavigate();
  const gettrip = () => {
    setPageloader(true);
    api
      .getTripLocation(tamp_link)
      .then((res) => {
        setLatitude(res?.data?.data?.trip_data?.lat);
        setLongitude(res?.data?.data?.trip_data?.lng);
        setUserdata(res?.data?.data?.user_data);
        setCompdata(res?.data?.data?.comp_data);
        setTripdata(res?.data.data?.trip_data);
        setTrip_hdr(res?.data.data?.trip_hdr);
        setActive_status(res?.data?.data?.trip_data?.active_status);
        setIsLocation(res?.data?.data?.isLocation);
        setPageloader(false);
      })
      .catch((error) => {
        setPageloader(false);
      });
  };

  const userData = JSON.parse(localStorage.getItem("userData"));
  useEffect(() => {
    window.scrollTo(0, 0);
    gettrip();
    const id = setInterval(gettrip, 60000);
    setIntervalId(id);
    return () => clearInterval(id);
  }, [tamp_link]);

  useEffect(() => {
    if (tripdata.is_active === 3 && intervalId) {
      clearInterval(intervalId);
    }
  }, [tripdata, intervalId]);

  const containerStyle = {
    width: "100wh",
    height: "90vh",
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_Key,
  });

  const customMarkerIcon = {
    url: iconImg,
    scaledSize: new window.google.maps.Size(40, 40),
  };

  const [map, setMap] = useState(null);
  const onLoad = useCallback((map) => setMap(map), []);
  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();

      bounds.extend({
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
      });

      map.fitBounds(bounds);
    }
  }, [map, latitude, longitude]);

  const [selectedMarker, setSelectedMarker] = useState(null);
  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  if (loadError)
    return <div>Error loading Google Maps API: {loadError.message}</div>;
  if (!isLoaded || pageloader) return <div>Loading...</div>;

  return (
    <div className="map p-2">
      <>
        <div className="container-fluid">
          {tripdata.is_active == 2 ? (
            <>
              <div className="container">
                {/* <button
                onClick={() => {
                  navigate("/home");
                }}
              >
                Go Back
              </button> */}
                <div className="row align-items-center">
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="d-flex align-items-center gap-2">
                      <img src={compdata?.logo} alt="" width="80" height="80" />
                      <div className="details">
                        <h2 className="m-0">{compdata?.comp_name}</h2>
                        <h4 className="m-0">{compdata?.comp_location}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={
                  {
                    // display: "grid",
                    // placeItems: "center",
                  }
                }
              >
                <div>
                  {/* <h1 className="m-0">
                    Your Order is On the Way
                    <img src={bike} alt="" width="40" height="40" />
                  </h1> */}
                  {compdata.is_conf == 1 ? (
                    <>
                      <div className="user_data" style={{ width: "280px" }}>
                        {/* <h1
                          className="headername m-0"
                          style={{ fontSize: "20px" }}
                        >
                         Salesman
                        </h1> */}
                        <h2>
                          Username : {`${userdata?.fname} ${userdata?.lname}`}
                        </h2>
                        <p>Mobile : {`${userdata?.mobile}`}</p>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {active_status != 1 || isLocation != 1 ? (
                <div
                  className="offline-message text-center"
                  style={{
                    height: "100px",
                    padding: "20px 0",
                  }}
                >
                  {userData?.data?.comp_data?.comp_type == 2 ? (
                    <h1 className="mt-4">Salesman is Offline. !</h1>
                  ) : (
                    <h1 className="mt-4">Delivery Boy is Offline. !</h1>
                  )}
                  <p>
                    Last Online Time:{" "}
                    {moment(tripdata.sync_at).format("DD/MM/YYYY  hh:mm:ss A")}
                  </p>
                </div>
              ) : null}
              {pageloader ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "400px",
                  }}
                >
                  <l-infinity
                    size="100"
                    stroke="4"
                    stroke-length="0.15"
                    bg-opacity="0.1"
                    speed="1.3"
                    color="#6afcf9"
                  ></l-infinity>
                </div>
              ) : (
                <div className="mapbox mt-4">
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    // center={{
                    //   lat: parseFloat(latitude),
                    //   lng: parseFloat(longitude),
                    // }}
                    onLoad={onLoad}
                    zoom={20}
                  >
                    <Marker
                      position={{
                        lat: parseFloat(latitude),
                        lng: parseFloat(longitude),
                      }}
                      icon={customMarkerIcon}
                      onClick={() => handleMarkerClick(tripdata)}
                    />

                    {selectedMarker && (
                      <InfoWindow
                        position={{
                          lat: parseFloat(latitude),
                          lng: parseFloat(longitude),
                        }}
                        onCloseClick={() => setSelectedMarker(null)}
                        options={{
                          pixelOffset: new window.google.maps.Size(0, -40),
                        }}
                      >
                        <div style={{ color: "black" }}>
                          <h2 style={{ color: "black" }}>
                            {userdata?.fname} {userdata?.lname}
                          </h2>
                          <p style={{ color: "black" }}>
                            Mobile: {`${userdata?.mobile}`}
                          </p>
                          <p style={{ color: "black" }}>
                            Start Trip:{" "}
                            {trip_hdr?.start_trip
                              ? moment(trip_hdr?.start_trip).format(
                                  "DD/MM/YYYY hh:mm A"
                                )
                              : "N/A"}
                          </p>

                          {/* <p style={{ color: "black", marginBottom: "15px" }}>
                            End Trip:{" "}
                            {selectedMarker?.end_trip
                              ? selectedMarker?.end_trip
                              : "N/A"}
                          </p> */}

                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSelectedMarker(null);
                            }}
                          >
                            Close
                          </span>
                        </div>
                      </InfoWindow>
                    )}
                  </GoogleMap>
                </div>
              )}
            </>
          ) : tripdata.is_active === 3 ? (
            <div className="container">
              <div
                style={{
                  height: "500px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "90px",
                }}
              >
                <div className="box text-center">
                  <h1 style={{ textAlign: "center" }}>
                    Your order has been delivered successfully.
                  </h1>
                  <p>Thank You ! 😊</p>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div className="footer p-0">
          <h4
            className="footheader text-center mt-2"
            style={{ fontSize: "16px" }}
          >
            Powered by:{" "}
            <a
              href="https://www.cabbagesoft.com"
              target="#blank"
              style={{ textDecoration: "none", color: "#6afcf9" }}
            >
              Cabbagesoft Technologies
            </a>
          </h4>
        </div>
      </>
      {/* )} */}
    </div>
  );
};

export default Map;
