import Switch from "@mui/material/Switch";
import React, { useEffect, useRef, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { BsCopy } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import { FiMapPin } from "react-icons/fi";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import Editmodal from "./Editmodal";
import "./Home/Home.css";
import logo from "./Login/biker_image.png";
import axios from "axios";
import api from "../api";
import { IP_URL } from "../config";
import { IoEyeSharp } from "react-icons/io5";
import ViewModal from "./ViewModal";
import { getOption } from "../utils";
import profile from './Login/profile.jpg'

const Cardbox = ({
  data,
  setDeleted,
  deleted,
  setEdited,
  edited,
  pltdata,
  getlist,
  groupList
}) => {
  const mapbtnRef = useRef(null);
  const coppyRef = useRef(null);
  const editRef = useRef(null);
  const viewRef = useRef(null);
  const deletRef = useRef(null);
  const [pathLink, setPathLink] = useState(`${IP_URL}/map/${data.tamp_link}`);

  const [copyStatus, setCopyStatus] = useState(null);
  const [show, setShow] = useState(false);
  const [deleteloader, setDeleteloader] = useState(false);
  const [maploader, setMaploader] = useState(false);

  const [checked, setChecked] = useState(false);

  const handleToggle = () => {
    setChecked((prevChecked) => !prevChecked);
  };

  const navigate = useNavigate();
  // ---modal close on----
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // -----tooltip-----
  useEffect(() => {
    tippy(mapbtnRef.current, {
      content: "Go to Map",
      placement: "top",
    });
    tippy(coppyRef.current, {
      content: "Click to Copy URL",
      placement: "top",
    });
    tippy(editRef.current, {
      content: "Edit",
      placement: "top",
    });
    tippy(viewRef.current, {
      content: "View",
      placement: "top",
    });
    tippy(deletRef.current, {
      content: "Delete",
      placement: "top",
    });
  }, []);
  // -----------copy funtion----------
  const handleCopyClick = () => {
    try {
      const textarea = document.createElement("textarea");
      textarea.value = pathLink;
      document.body.appendChild(textarea);

      textarea.select();
      document.execCommand("copy");

      document.body.removeChild(textarea);

      setCopyStatus("success");

      setTimeout(() => {
        setCopyStatus(null);
      }, 2000);
    } catch (err) {
      setCopyStatus("error");

      setTimeout(() => {
        setCopyStatus(null);
      }, 2000);
    }
  };

  // -------------delete  delivery boy-----------
  const userData = JSON.parse(localStorage.getItem("userData"));
  const handledelete = async () => {
    Swal.fire({
      title: "<span style='color: #6afcf9'>Are you sure? </span>",
      html: "<p style='color: #6afcf9'>You won't be able to revert this! </p>",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
      customClass: {
        popup: "custom-swal-modal",
        content: "custom-swal-content",
        confirmButton: "custom-swal-confirm-button",
        cancelButton: "custom-swal-cancel-button",
      },
      allowHtml: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setDeleteloader(true);
        api.cancelUser(userData, data.user_id).then((res) => {
          setDeleted(!deleted);
          setDeleteloader(false);
          Swal.fire({
            html: "<span style='color: #6afcf9'> Deleted</span>",
            icon: "success",
            customClass: {
              popup: "custom-swal-modal",
              content: "custom-swal-content",
              confirmButton: "custom-swal-confirm-button",
              cancelButton: "custom-swal-cancel-button",
            },
          });
        });
      }
    });
  };

  const handleGoMap = () => {
    navigate(`/map/${data.tamp_link}`);
  };

  return (
    <div className="cards">
      <div class="profile-card ">
        <div className="status">
          {data?.status == 1 ? (
            <Spinner
              animation="fix"
              variant="denger"
              style={{
                backgroundColor: "red",
                height: "20px",
                width: "20px",
                borderRadius: "50%",
              }}
            />
          ) : data?.status == 2 ? (
            <Spinner
              animation="grow"
              variant="success"
              style={{
                background: "#66FF00",
                height: "20px",
                width: "20px",
              }}
            />
          ) : data?.status == 3 ? (
            <Spinner
              animation="fix"
              variant="denger"
              style={{
                backgroundColor: "red",
                height: "20px",
                width: "20px",
                borderRadius: "50%",
              }}
            />
          ) : null}
        </div>

        {
          data.role_id == 3 ? <img src={logo} alt="John Alan" /> : <img src={profile} alt="John Alan" />
        }
        <h2 class="name">{`${data.fname} ${data.lname}`}</h2>
        <p class="occupation">
          <i class="fas fa-map-marker-alt"></i> {data.username}
        </p>
        {userData?.data?.comp_data.comp_type === 2 && (

          <p>
            Group :{" "}
            {data?.grp_name}
          </p>
        )}

        <div style={{ height: "90px" }}>
          {data.status === 2 ? (
            <>
              <div className="online_offline_status text-center">
                {data.active_status === 1 ? (
                  <span>Online</span>
                ) : (
                  <span style={{ color: "red" }}>Offline</span>
                )}
              </div>
              <div className="location">
                <p>
                  Location is{" "}
                  {data?.isLocation == true ? (
                    "ON"
                  ) : (
                    <span
                      style={{
                        color: "rgb(243, 12, 12)",
                        fontWeight: "bolder",
                      }}
                    >
                      OFF
                    </span>
                  )}
                </p>
              </div>
            </>
          ) : null}
        </div>

        <div className="btns d-flex gap-2">
          {
            userData?.data?.user_data?.role_id == 2 ?
              <button className="map-btn" ref={editRef} onClick={handleShow}>
                <FaRegEdit />
              </button> : ""
          }

          {userData?.data?.comp_data.comp_type == 2 ? (
            <button
              className="map-btn"
              ref={viewRef}
              onClick={() => navigate("/View", { state: { data: data } })}
            >
              <IoEyeSharp style={{ fontSize: "18px" }} />
            </button>
          ) : (
            ""
          )}

          {data.status === 2 ? (
            <>
              <button
                className="map-btn"
                ref={coppyRef}
                onClick={handleCopyClick}
              >
                <BsCopy />
              </button>
              <button className="map-btn" ref={mapbtnRef} onClick={handleGoMap}>
                {maploader ? (
                  <Spinner animation="border" variant="dark" size="sm" />
                ) : (
                  <FiMapPin />
                )}
              </button>
            </>
          ) : (
            ""
          )}

          {
            userData?.data?.user_data?.role_id == 2 ?
              <>
                {data.status != 2 ? (
                  <button className="map-btn" ref={deletRef} onClick={handledelete}>
                    {deleteloader ? (
                      <Spinner animation="border" variant="dark" size="sm" />
                    ) : (
                      <RiDeleteBin5Fill />
                    )}
                  </button>
                ) : (
                  ""
                )}
              </>
              : ""
          }


        </div>
        {copyStatus === "success" && (
          <div className="copy-success-message">Copied to clipboard!</div>
        )}
        {copyStatus === "error" && (
          <div className="copy-error-message">
            Copy failed. Please try again.
          </div>
        )}
      </div>

      <Editmodal
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        setEdited={setEdited}
        edited={edited}
        data={data}
        getlist={getlist}
        groupList={groupList}
      />
    </div>
  );
};

export default Cardbox;
