import React, { useCallback, useEffect, useRef, useState } from "react";
import Appbar from "../Layouts/Appbar";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { GOOGLE_API_Key } from "../../config";
import iconImg from "../Login/biker_image.png";
import api from "../../api";
import moment from "moment";

const Allmap = () => {
  const [markersData, setMarkersData] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mapCenter, setMapCenter] = useState({ lat: 19.076, lng: 72.8777 });
  const mapRef = useRef(null);

  useEffect(() => {
    const fetchData = () => {
      api
        .tripLocationAll(userData)
        .then((res) => {
          setMarkersData(res.data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    };

    fetchData();

    const interval = setInterval(fetchData, 60000);

    return () => clearInterval(interval);
  }, []);

  const containerStyle = {
    width: "100vw",
    height: "100vh",
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_Key,
  });

  const customMarkerIcon = {
    url: iconImg,
    scaledSize: new window.google.maps.Size(40, 40),
  };

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
    if (
      marker.trip_data?.trip_dtl?.lat !== null &&
      marker.trip_data?.trip_dtl?.lng !== null &&
      !isNaN(parseFloat(marker.trip_data?.trip_dtl?.lat)) &&
      !isNaN(parseFloat(marker.trip_data?.trip_dtl?.lng))
    ) {
      setMapCenter({
        lat: parseFloat(marker.trip_data.trip_dtl.lat),
        lng: parseFloat(marker.trip_data.trip_dtl.lng),
      });
    }
  };

  const [map, setMap] = useState(null);
  const onLoad = useCallback((map) => setMap(map), []);
  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      markersData.map((marker) => {
        if (
          marker.trip_data.trip_dtl.lat !== null &&
          marker.trip_data.trip_dtl.lng !== null &&
          !isNaN(parseFloat(marker.trip_data.trip_dtl.lat)) &&
          !isNaN(parseFloat(marker.trip_data.trip_dtl.lng))
        ) {
          bounds.extend({
            lat: parseFloat(marker.trip_data?.trip_dtl?.lat),
            lng: parseFloat(marker.trip_data.trip_dtl.lng),
          });
        }
      });

      map.fitBounds(bounds);
    }
  }, [map, markersData]);

  if (!isLoaded || loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="AllMaps">
      <div className="mapbox">
        <GoogleMap
          mapContainerStyle={containerStyle}
          // center={mapCenter}
          zoom={10}
          onLoad={onLoad}
        >
          {markersData.map(
            (marker, index) =>
              marker.trip_data?.trip_dtl?.lat !== null &&
              marker.trip_data?.trip_dtl?.lng !== null &&
              !isNaN(parseFloat(marker.trip_data?.trip_dtl?.lat)) &&
              !isNaN(parseFloat(marker.trip_data?.trip_dtl?.lng)) && (
                <Marker
                  key={index}
                  position={{
                    lat: parseFloat(marker.trip_data?.trip_dtl?.lat),
                    lng: parseFloat(marker.trip_data?.trip_dtl?.lng),
                  }}
                  icon={customMarkerIcon}
                  onClick={() => handleMarkerClick(marker)}
                />
              )
          )}

          {selectedMarker &&
            selectedMarker.trip_data.trip_dtl.lat !== null &&
            selectedMarker.trip_data.trip_dtl.lng !== null &&
            !isNaN(parseFloat(selectedMarker.trip_data.trip_dtl.lat)) &&
            !isNaN(parseFloat(selectedMarker.trip_data.trip_dtl.lng)) && (
              <InfoWindow
                position={{
                  lat: parseFloat(selectedMarker.trip_data.trip_dtl.lat),
                  lng: parseFloat(selectedMarker.trip_data.trip_dtl.lng),
                }}
                onCloseClick={() => setSelectedMarker(null)}
                options={{
                  pixelOffset: new window.google.maps.Size(0, -40),
                }}
              >
                <div
                  style={{
                    background: "white",
                    padding: "3px",
                    color: "black",
                  }}
                >
                  <h3 style={{ fontSize: "12px" }}>
                    Name: {selectedMarker.user_data.fname}{" "}
                    {selectedMarker.user_data.lname}
                  </h3>
                  <p>Mobile: {selectedMarker.user_data.mobile}</p>
                  <p>
                    Start trip:{" "}
                    {moment(
                      selectedMarker?.trip_data?.trip_hdr?.start_trip
                    ).format("DD/MM/YYYY hh:mm A")}
                  </p>

                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSelectedMarker(null);
                    }}
                  >
                    Close
                  </span>
                </div>
              </InfoWindow>
            )}
        </GoogleMap>
      </div>
    </div>
  );
};

export default Allmap;
